const appActions = {
  GET_SHOP: "GET_SHOP",
  GET_SHOP_SUCCESS: "GET_SHOP_SUCCESS",
  GET_SHOP_ERROR: "GET_SHOP_ERROR",

  GET_COUNT_TRANSFER: "GET_COUNT_TRANSFER",
  GET_COUNT_TRANSFER_SUCCESS: "GET_COUNT_TRANSFER_SUCCESS",
  GET_COUNT_TRANSFER_ERROR: "GET_COUNT_TRANSFER_ERROR",

  GET_COUNT_CONTRACT: "GET_COUNT_CONTRACT",
  GET_COUNT_CONTRACT_SUCCESS: "GET_COUNT_CONTRACT_SUCCESS",
  GET_COUNT_CONTRACT_ERROR: "GET_COUNT_CONTRACT_ERROR",

  GET_MONEY: "GET_MONEY",
  GET_MONEY_SUCCESS: "GET_MONEY_SUCCESS",
  GET_MONEY_ERROR: "GET_MONEY_ERROR",

  GET_DAY_SCHEDULE: "GET_DAY_SCHEDULE",
  GET_DAY_SCHEDULE_SUCCESS: "GET_DAY_SCHEDULE_SUCCESS",
  GET_DAY_SCHEDULE_ERROR: "GET_DAY_SCHEDULE_ERROR",

  GET_PAYMENT_SCHEDULE: "GET_PAYMENT_SCHEDULE",
  GET_PAYMENT_SCHEDULE_SUCCESS: "GET_PAYMENT_SCHEDULE_SUCCESS",
  GET_PAYMENT_SCHEDULE_ERROR: "GET_PAYMENT_SCHEDULE_ERROR",

  GET_ADS: "GET_ADS",
  GET_ADS_SUCCESS: "GET_ADS_SUCCESS",
  GET_ADS_ERROR: "GET_ADS_ERROR",

  GET_TELESALES: "GET_TELESALES",
  GET_TELESALES_SUCCESS: "GET_TELESALES_SUCCESS",
  GET_TELESALES_ERROR: "GET_TELESALES_ERROR",

  GET_STAFF_SHOP: "GET_STAFF_SHOP",
  GET_STAFF_SHOP_SUCCESS: "GET_STAFF_SHOP_SUCCESS",
  GET_STAFF_SHOP_ERROR: "GET_STAFF_SHOP_ERROR",

  GET_SALES: "GET_SALES",
  GET_SALES_SUCCESS: "GET_SALES_SUCCESS",
  GET_SALES_ERROR: "GET_SALES_ERROR",

  GET_CUSTOMER_APPOINTMENT: "GET_CUSTOMER_APPOINTMENT",
  GET_CUSTOMER_APPOINTMENT_SUCCESS: "GET_CUSTOMER_APPOINTMENT_SUCCESS",
  GET_CUSTOMER_APPOINTMENT_ERROR: "GET_CUSTOMER_APPOINTMENT_ERROR",

  getShop: () => ({
    type: appActions.GET_SHOP,
  }),
  getShopSuccess: (result: any) => ({
    type: appActions.GET_SHOP_SUCCESS,
    result,
  }),
  getShopError: () => ({
    type: appActions.GET_SHOP_ERROR,
  }),

  getCountTransfer: () => ({
    type: appActions.GET_COUNT_TRANSFER,
  }),
  getCountTransferSuccess: (result: any) => ({
    type: appActions.GET_COUNT_TRANSFER_SUCCESS,
    result,
  }),
  getCountTransferError: () => ({
    type: appActions.GET_COUNT_TRANSFER_ERROR,
  }),

  getCountContract: () => ({
    type: appActions.GET_COUNT_CONTRACT,
  }),
  getCountContractSuccess: (result: any) => ({
    type: appActions.GET_COUNT_CONTRACT_SUCCESS,
    result,
  }),
  getCountContractError: () => ({
    type: appActions.GET_COUNT_CONTRACT_ERROR,
  }),

  // lấy số tiền user đăng nhập
  getMoney: () => ({
    type: appActions.GET_MONEY,
  }),
  getMoneySuccess: (result: any) => ({
    type: appActions.GET_MONEY_SUCCESS,
    result,
  }),
  getMoneyError: () => ({
    type: appActions.GET_MONEY_ERROR,
  }),

  // lấy số số khách hàng hẹn trong ngày
  getDaySchedule: () => ({
    type: appActions.GET_DAY_SCHEDULE,
  }),
  getDayScheduleSuccess: (result: any) => ({
    type: appActions.GET_DAY_SCHEDULE_SUCCESS,
    result,
  }),
  getDayScheduleError: () => ({
    type: appActions.GET_DAY_SCHEDULE_ERROR,
  }),

  // lấy số số khách hàng hẹn trong ngày
  getPaymentSchedule: () => ({
    type: appActions.GET_PAYMENT_SCHEDULE,
  }),
  getPaymentScheduleSuccess: (result: any) => ({
    type: appActions.GET_PAYMENT_SCHEDULE_SUCCESS,
    result,
  }),
  getPaymentScheduleError: () => ({
    type: appActions.GET_PAYMENT_SCHEDULE_ERROR,
  }),

  // danh sách ADS
  getAds: () => ({
    type: appActions.GET_ADS,
  }),
  getAdsSuccess: (result: any) => ({
    type: appActions.GET_ADS_SUCCESS,
    result,
  }),
  getAdsError: () => ({
    type: appActions.GET_ADS_ERROR,
  }),

  // danh sách Telesales
  getTelesales: () => ({
    type: appActions.GET_TELESALES,
  }),
  getTelesalesSuccess: (result: any) => ({
    type: appActions.GET_TELESALES_SUCCESS,
    result,
  }),
  getTelesalesError: () => ({
    type: appActions.GET_TELESALES_ERROR,
  }),

  // danh sách staff shop
  getStaffShop: () => ({
    type: appActions.GET_STAFF_SHOP,
  }),
  getStaffShopSuccess: (result: any) => ({
    type: appActions.GET_STAFF_SHOP_SUCCESS,
    result,
  }),
  getStaffShopError: () => ({
    type: appActions.GET_STAFF_SHOP_ERROR,
  }),

  // danh sách staff shop
  getSales: () => ({
    type: appActions.GET_SALES,
  }),
  getSalesSuccess: (result: any) => ({
    type: appActions.GET_SALES_SUCCESS,
    result,
  }),
  getSalesError: () => ({
    type: appActions.GET_SALES_ERROR,
  }),

  // danh sách khách hẹn thanh toán
  getAppointment: () => ({
    type: appActions.GET_CUSTOMER_APPOINTMENT,
  }),
  getAppointmentSuccess: (result: any) => ({
    type: appActions.GET_CUSTOMER_APPOINTMENT_SUCCESS,
    result,
  }),
  getAppointmentError: () => ({
    type: appActions.GET_CUSTOMER_APPOINTMENT_ERROR,
  }),
};
export default appActions;
