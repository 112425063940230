import axios, { AxiosRequestConfig } from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../constants";
import { pathname } from "../routers/path";
import { serviceOptions } from "../services/serviceOptions";
import { getShop } from "../utils";

export const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();

    const checkAuth = () => {
      const token = localStorage.getItem("token");
      if (!token) navigate(pathname.login, { replace: true });
      else {
        //@ts-ignore
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
          localStorage.clear();
          navigate(pathname.login, { replace: true });
        }
        const axiosConfig: AxiosRequestConfig = {
          baseURL: BASE_URL,
          timeout: 60000, // 1 phút
        };
        axiosConfig.headers = {
          Token: token,
          "Access-Control-Allow-Origin": "*",
          UserID: user?.UserID,
          ShopID: getShop()?.ShopID,
        };

        serviceOptions.axios = axios.create(axiosConfig);
      }
    };

    useEffect(() => {
      checkAuth();
    }, [location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
