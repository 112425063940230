export const pathname = {
    root: "/",
    login: "/auth/login",
    profile: '/auth/profile',
    contract: {
        new: "/contract/new",
        treated: "/contract/treated",
        end: "/contract/end",
        lookup: "/contract/lookup",
        request: "/contract/request",
        pending: "/contract/pending",
        cantworking: '/contract/cantworking',
        document: "/contract/document"
    },
    finance: {
        capital: "/finance/capital",
        revenueAndExpenditure: "/finance/revenueAndExpenditure",
        transfer: "/finance/transfer",
        report: "/finance/report",
        wallet: "/finance/wallet"
    },
    manager: {
        inCourse: "/manager/inCourse",
        overdue: "/manager/overdue",
        quit: "/manager/quit"
    },
    settings: '/settings',
    customer: {
        customer: "/customer",
        partner: "/customer/partner",
        check: "/customer/check",
        telesales: "/customer/telesalesReport",
        IntroPerson: "/customer/IntroPerson",
        marketing: "/marketing"
    },
    report: {
        cashbook: "/report/cashbook",
        profit: "/report/profit",
        receiveInterest: '/report/receiveInterest',
        ReportPawnHolding: "/report/ReportPawnHolding",
        ReportContractCancel: "/report/ReportContractCancel",
        ReportPawnNewLiquidation: "/report/ReportPawnNewLiquidation",
        ReportPawnNewRepurchase: "/report/ReportPawnNewRepurchase",
        WarehouseLiquidation: "/report/WarehouseLiquidation",
        ReportBlance: "/report/ReportBlance",
        ReportTreatment: '/report/ReportTreatment'
    },
    marketing: {
        campain: "/campain/index"
    },
    admin: {
        account: "/admin/manager_account"
    }
}