import { LockOutlined } from "@ant-design/icons";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios, { AxiosRequestConfig } from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import { UserService } from "../../services";
import { serviceOptions } from "../../services/serviceOptions";
import { error, toastError } from "../../utils/toast";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import appActions from "../../hooks/app/actions";
import { getShop } from "../../utils";
import { BASE_URL } from "../../constants";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: BASE_URL,
      timeout: 60000, // 1 phút
    };
    serviceOptions.axios = axios.create(axiosConfig);
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsLoading(true);
    UserService.login({
      body: {
        //@ts-ignore
        UserName: data.get("username"),
        //@ts-ignore
        Password: data.get("password"),
      },
    })
      .then(async (response) => {
        setIsLoading(false);
        if (response.Result === 1) {
          //@ts-ignore
          localStorage.setItem("token", response.Data?.Token);
          localStorage.setItem("user", JSON.stringify(response.Data));
          localStorage.setItem(
            "shop",
            JSON.stringify({
              ShopID: response.Data?.ShopID,
              ShopName: response.Data?.ShopName,
            })
          );
          const axiosConfig: AxiosRequestConfig = {
            baseURL: BASE_URL,
            timeout: 600000, // 1 phút
          };
          axiosConfig.headers = {
            //@ts-ignore
            Token: response.Data?.Token,
            "Access-Control-Allow-Origin": "*",
            //@ts-ignore
            UserID: response.Data?.UserID,
            ShopID: getShop()?.ShopID,
          };
          serviceOptions.axios = axios.create(axiosConfig);
          dispatch(appActions.getShop());
          dispatch(appActions.getMoney());
          navigate("/", { replace: true });
        } else {
          toastError(response.Message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        error();
      });
  };
  return (
    //@ts-ignore
    <Page title="Đăng nhập hệ thống">
      <Grid container component={"main"} sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://images.unsplash.com/photo-1614624532983-4ce03382d63d?crop=entropy&cs=tinysrgb&fit=max&fm=webp&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDF8fHNldHVwfGVufDB8fHx8MTYzNDYxMDMwNw&ixlib=rb-1.2.1&q=80&w=1460)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Đăng nhập hệ thống
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Tên đăng nhập"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mật khẩu"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={
                  <Checkbox defaultChecked value="remember" color="primary" />
                }
                label="Nhớ mật khẩu"
              />
              <LoadingButton
                fullWidth
                loading={isLoading}
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                type="submit"
              >
                Đăng nhập
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
}
