/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

import { IRequestOptions, IRequestConfig, getConfigs, axios } from './serviceOptions';
export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class CampainService {
  /**
   * Thêm sửa chiến dịch
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppCampainAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Campain/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa chiến dịch
   */
  static delete(
    params: {
      /** Mã chiến dịch */
      campainId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Campain/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { campainId: params['campainId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách chiến dịch data khách hàng
   */
  static getCampainDataCustomer(
    params: {
      /** Mã nhân viên. -1 tất cả */
      staffId?: number;
      /** Mã dịch vụ. -1 tất cả */
      serviceId?: number;
      /** Từ ngày. null tất cả */
      fromDate?: string;
      /** Đến ngày. null tất cả */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppReportCampainDataCustomerListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Campain/GetCampainDataCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        staffId: params['staffId'],
        serviceId: params['serviceId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        status: params['status']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm sửa chiến dịch (thao tác)
   */
  static addOrEditCampainAction(
    params: {
      /**  */
      body?: AppCampainActionAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Campain/AddOrEditCampainAction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách chiến dịch (thao tác)
   */
  static getListCampainAction(
    params: {
      /** Mã chiến dịch */
      campainId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppGetListCampainActionListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Campain/GetListCampainAction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { campainId: params['campainId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * On hoặc Off chiến dịch
   */
  static onOrOff(
    params: {
      /** Mã chiến dịch */
      campainId?: number;
      /** 1: on, 0: tắt */
      isEnable?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Campain/OnOrOff';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { campainId: params['campainId'], isEnable: params['isEnable'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy báo cáo chiến dịch
   */
  static cancelCampainAction(
    params: {
      /** Mã báo cáo chiến dịch */
      campainActionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Campain/CancelCampainAction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { campainActionId: params['campainActionId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CapitalService {
  /**
   * Thêm sửa hợp đồng góp vốn
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppCapitalAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách hợp đồng
   */
  static getCapital(
    params: {
      /** mã cửa hàng */
      shopId?: number;
      /** tên người góp vốn */
      fullName?: string;
      /** từ ngày (null) */
      fromDate?: string;
      /** đến ngày (null) */
      toDate?: string;
      /** trạng thái hợp đồng (mặc định tất cả hđ chưa kết thúc (-1)) */
      status?: number;
      /** PageIndex */
      pageIndex?: number;
      /** PageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/GetCapital';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        shopId: params['shopId'],
        fullName: params['fullName'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        status: params['status'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy Action thêm vốn
   */
  static cancel(
    params: {
      /** Id cation thêm vốn */
      capitalId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/Cancel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { capitalId: params['capitalId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy lịch thanh toán
   */
  static getPaymentSchedule(
    params: {
      /** Id thêm vốn */
      capitalId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/GetPaymentSchedule';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { capitalId: params['capitalId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đóng lãi tùy biến
   */
  static getLoanPaymentCustomize(
    params: {
      /** ID Capital */
      contractId?: number;
      /** Đến ngày */
      strToDate?: string;
      /** Số ngày */
      totalDays?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/GetLoanPaymentCustomize';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        contractID: params['contractId'],
        strToDate: params['strToDate'],
        totalDays: params['totalDays']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đóng tiền lịch đóng tiền
   */
  static customerPaymentSchedule(
    params: {
      /**  */
      body?: AppCustomerPayment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/CustomerPaymentSchedule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy đóng lãi
   */
  static cancelPaymentContract(
    params: {
      /** ID HĐ */
      contractId?: number;
      /** ID bản ghi */
      paymentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/CancelPaymentContract';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractID: params['contractId'], paymentID: params['paymentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đóng tiền tùy biến (FromDAte lấy L)
   */
  static paymentContractCustomize(
    params: {
      /**  */
      body?: PaymentContractCustomize;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/PaymentContractCustomize';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Trả tiền gốc
   */
  static insertLoanExtra(
    params: {
      /**  */
      body?: RequestInsertLoanExtra;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/InsertLoanExtra';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * vay thêm tiền gốc
   */
  static borrowLoanExtra(
    params: {
      /**  */
      body?: RequestInsertLoanExtra;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/BorrowLoanExtra';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử trả gốc
   */
  static getLoanExtra(
    params: {
      /** ID HĐ */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/GetLoanExtra';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractID: params['contractId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy tiền gốc
   */
  static deleteLoanExtra(
    params: {
      /**  */
      contractId?: number;
      /**  */
      loanExtraId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/DeleteLoanExtra';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractID: params['contractId'], loanExtraID: params['loanExtraId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đóng lãi tùy biến
   */
  static getCloseCapital(
    params: {
      /** ID Capital */
      contractId?: number;
      /** yyyy-MM-dd */
      closeDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/GetCloseCapital';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractID: params['contractId'], closeDate: params['closeDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * đóng hđ
   */
  static closeContract(
    params: {
      /**  */
      body?: RequestCloseContract;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/CloseContract';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Gia han
   */
  static extentionTime(
    params: {
      /**  */
      body?: RequestExtendTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/ExtentionTime';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * lịch sử gia hạn
   */
  static historyExtentionTime(
    params: {
      /**  */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Capital/HistoryExtentionTime';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractID: params['contractId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CapitalExtraService {
  /**
   * Thêm vốn/Trả bớt vốn
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppCapitalExtraAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CapitalExtra/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử thêm bớt vốn
   */
  static getCapitalExtraHistory(
    params: {
      /** id của nguồn vốn */
      capitalId?: number;
      /** 1: thêm vốn, 2 bớt vốn */
      type?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCapitalExtraHistoryListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CapitalExtra/GetCapitalExtraHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { capitalId: params['capitalId'], type: params['type'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CapitalHistoryService {
  /**
   * Lấy danh sách lịch sử
   */
  static getCapitalHistory(
    params: {
      /** Mã HĐ */
      capitalId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCapitalHistoryViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CapitalHistory/GetCapitalHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { capitalId: params['capitalId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ConfigServiceService {
  /**
   * Thêm sửa dịch vụ
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppConfigServiceAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigService/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa dịch vụ
   */
  static delete(
    params: {
      /** ID dịch vụ bị xóa */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigService/Delete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách dịch vụ
   */
  static getConfigService(
    params: {
      /** Tên dịch vụ */
      name?: string;
      /** Trạng thái : 1 hoạt động , -1 tất cả , 0 đã khóa */
      status?: number;
      /** Id shop muốn xem dịch vụ */
      shopId?: number;
      /** Loại dịch vụ 1: Spa, 2: Thuê xe, -1: Tất cả */
      type?: number;
      /** PageIndex */
      pageIndex?: number;
      /** PageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppConfigServiceViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigService/GetConfigService';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Status: params['status'],
        ShopId: params['shopId'],
        Type: params['type'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách dịch vụ theo ShopID
   */
  static getConfigServiceByShopActive(
    params: {
      /** ID cửa hàng */
      shopId?: number;
      /** Loại dịch vụ 1: Spa, 2: Thuê xe */
      type?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppConfigServiceByShopViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigService/GetConfigServiceByShopActive';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { shopId: params['shopId'], type: params['type'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ConfigUserWalletService {
  /**
   * Thêm sửa cấu hình
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppConfigUserWalletAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigUserWallet/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa cấu hình
   */
  static delete(
    params: {
      /** Mã cấu hình */
      configUserWalletId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigUserWallet/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { configUserWalletId: params['configUserWalletId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm tiền cho nhân viên
   */
  static addMoney(
    params: {
      /**  */
      body?: AppAddMoney;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigUserWallet/AddMoney';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách log cấu hình
   */
  static getLog(
    params: {
      /** Mã cấu hình */
      configUserWalletId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppGetLogListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigUserWallet/GetLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { configUserWalletId: params['configUserWalletId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách cấu hình
   */
  static getList(
    params: {
      /** Mã ví. -1 tất cả */
      walletId?: number;
      /** Mã nhân viên. -1 tất cả */
      userSearchId?: number;
      /** Mã phòng ban. -1 tất cả */
      departmentId?: number;
      /** trạng thái. -1 tất cả */
      status?: number;
      /** pageIndex */
      pageIndex?: number;
      /** pageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppConfigUserWallerListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigUserWallet/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        walletId: params['walletId'],
        userSearchId: params['userSearchId'],
        departmentId: params['departmentId'],
        status: params['status'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy tổng số tiền theo phòng ban
   */
  static getMoneyByDepartment(
    params: {
      /**  */
      departmentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigUserWallet/GetMoneyByDepartment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { departmentId: params['departmentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy tổng số tiền theo tài khoản
   */
  static getMoneyByUser(
    params: {
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ConfigUserWallet/GetMoneyByUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ContractService {
  /**
   * Thêm hoặc sửa HĐ
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppContractAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách hợp đồng
   */
  static getContract(
    params: {
      /** Mã HĐ (null) */
      contractId?: string;
      /** Mã media (null) */
      mediaId?: string;
      /** nhân viên -1 tất cả */
      staffId?: number;
      /** dịch vụ -1 tất cả */
      serviceId?: number;
      /** sản phẩm -1 tất cả */
      productId?: number;
      /** số điện thoại (null) */
      phone?: string;
      /** tên khách hàng (null) */
      fullName?: string;
      /** từ ngày (null) */
      fromDate?: string;
      /** đến ngày (null) */
      toDate?: string;
      /** trạng thái hợp đồng (mặc định chờ duyệt) */
      status?: number;
      /** PageIndex */
      pageIndex?: number;
      /** PageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetContract';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        contractId: params['contractId'],
        mediaId: params['mediaId'],
        staffId: params['staffId'],
        serviceId: params['serviceId'],
        productId: params['productId'],
        phone: params['phone'],
        fullName: params['fullName'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        status: params['status'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách hợp đồng cần đóng tiền
   */
  static getContractNotify(
    params: {
      /** Mã HĐ (null) */
      contractId?: string;
      /** Mã media (null) */
      mediaId?: string;
      /** nhân viên -1 tất cả */
      staffId?: number;
      /** dịch vụ -1 tất cả */
      serviceId?: number;
      /** số điện thoại (null) */
      phone?: string;
      /** tên khách hàng (null) */
      fullName?: string;
      /** PageIndex */
      pageIndex?: number;
      /** PageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetContractNotify';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        contractId: params['contractId'],
        mediaId: params['mediaId'],
        staffId: params['staffId'],
        serviceId: params['serviceId'],
        phone: params['phone'],
        fullName: params['fullName'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy hợp đồng
   */
  static cancelContract(
    params: {
      /**  */
      body?: AppContractId;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/CancelContract';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử đóng tiền
   */
  static historyPaymentSpa(
    params: {
      /** ID bản ghi */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppHistoryPaymentSpaListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/HistoryPaymentSpa';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractID: params['contractId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Load ra số liệu tổng hợp của ví và HĐ
   */
  static getReportWalletAndContract(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetReportWalletAndContract';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tạo mới chứng từ HĐ
   */
  static createFileContract(
    params: {
      /**  */
      body?: AppCreateFileContractAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/CreateFileContract';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách chứng từ HĐ
   */
  static getFileContract(
    params: {
      /** Mã HĐ */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetFileContract';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { contractId: params['contractId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách lịch sử
   */
  static getContractHistory(
    params: {
      /** Mã HĐ */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppContractHistoryViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetContractHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractId: params['contractId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tạo mới hẹn giờ HĐ
   */
  static createContractTimer(
    params: {
      /**  */
      body?: AppContractTimerAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/CreateContractTimer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách hẹn giờ HĐ
   */
  static getContractTimer(
    params: {
      /** Mã HĐ */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractTimerViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetContractTimer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { contractId: params['contractId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách lịch đóng tiền
   */
  static getContractPayment(
    params: {
      /** Mã HĐ */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetContractPayment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractId: params['contractId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đóng lãi tùy biến
   */
  static getLoanPaymentCustomize(
    params: {
      /** ID HĐ */
      contractId?: number;
      /** Đến ngày */
      strToDate?: string;
      /** Số ngày */
      totalDays?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetLoanPaymentCustomize';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        contractID: params['contractId'],
        strToDate: params['strToDate'],
        totalDays: params['totalDays']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đóng tiền lịch đóng tiền
   */
  static customerPaymentSchedule(
    params: {
      /**  */
      body?: AppCustomerPayment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/CustomerPaymentSchedule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy đóng lãi
   */
  static cancelPaymentContract(
    params: {
      /** ID HĐ */
      contractId?: number;
      /** ID bản ghi */
      paymentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/CancelPaymentContract';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractID: params['contractId'], paymentID: params['paymentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đóng tiền tùy biến (FromDAte lấy L)
   */
  static paymentContractCustomize(
    params: {
      /**  */
      body?: PaymentContractCustomize;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/PaymentContractCustomize';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tạo mới liệu trình
   */
  static createContractCourse(
    params: {
      /**  */
      body?: AppContractCourseAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/CreateContractCourse';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách liệu trình
   */
  static getContractCourse(
    params: {
      /** Mã HĐ */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractCourseViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetContractCourse';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { contractId: params['contractId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đóng tiền gốc
   */
  static insertLoanExtra(
    params: {
      /**  */
      body?: RequestInsertLoanExtra;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/InsertLoanExtra';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lịch sử trả gốc
   */
  static getLoanExtra(
    params: {
      /** ID HĐ */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetLoanExtra';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractID: params['contractId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy tiền gốc
   */
  static deleteLoanExtra(
    params: {
      /**  */
      contractId?: number;
      /**  */
      loanExtraId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/DeleteLoanExtra';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractID: params['contractId'], loanExtraID: params['loanExtraId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách hợp đồng đến kỳ điều trị
   */
  static getContractTreatment(
    params: {
      /** PageIndex */
      pageIndex?: number;
      /** PageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetContractTreatment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { pageIndex: params['pageIndex'], pageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách liệu trình v2
   */
  static getContractTreatment1(
    params: {
      /** Mã HĐ */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractTreatmentViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetContractTreatment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { contractId: params['contractId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thay đổi trạng thái
   */
  static changeStatus(
    params: {
      /**  */
      body?: AppChangeStatusContract;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/ChangeStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách hợp đồng theo trạng thái
   */
  static statusSearch(
    params: {
      /** Mã HĐ (null) */
      contractId?: string;
      /** Mã media (null) */
      mediaId?: string;
      /** nhân viên -1 tất cả */
      staffId?: number;
      /** dịch vụ -1 tất cả */
      serviceId?: number;
      /** sản phẩm -1 tất cả */
      productId?: number;
      /** số điện thoại (null) */
      phone?: string;
      /** tên khách hàng (null) */
      fullName?: string;
      /** từ ngày (null) */
      fromDate?: string;
      /** đến ngày (null) */
      toDate?: string;
      /** trạng thái hợp đồng (mặc định chờ duyệt) */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/StatusSearch';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        contractId: params['contractId'],
        mediaId: params['mediaId'],
        staffId: params['staffId'],
        serviceId: params['serviceId'],
        productId: params['productId'],
        phone: params['phone'],
        fullName: params['fullName'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        status: params['status']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Load ra số liệu số tiền các ví như màn thu chi + tổng tiền dịch vụ + tổng tiền thanh toán
   */
  static getMoneyWalletAndContract(
    params: {
      /**  */
      contractId?: string;
      /**  */
      mediaId?: string;
      /**  */
      staffId?: number;
      /**  */
      serviceId?: number;
      /**  */
      phone?: string;
      /**  */
      fullName?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      status?: number;
      /**  */
      productId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetMoneyWalletAndContract';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        contractId: params['contractId'],
        mediaId: params['mediaId'],
        staffId: params['staffId'],
        serviceId: params['serviceId'],
        phone: params['phone'],
        fullName: params['fullName'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        status: params['status'],
        productId: params['productId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tạo mới liệu trình v2
   */
  static createContractTreatment(
    params: {
      /**  */
      body?: AppContractTreatmentAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/CreateContractTreatment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách khách hẹn thanh toán
   */
  static getCustomerPaymentAppointment(options: IRequestOptions = {}): Promise<ContractViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetCustomerPaymentAppointment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Sửa hẹn giờ HĐ
   */
  static editContractTimer(
    params: {
      /**  */
      body?: AppContractTimerEdit;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/EditContractTimer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa hẹn giờ
   */
  static deleteTimer(
    params: {
      /** Mã chiến dịch */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/DeleteTimer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy chi tiết chi phí hợp đồng
   */
  static getDetailExpenseLoan(
    params: {
      /**  */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/GetDetailExpenseLoan';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractID: params['contractId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa chứng từ
   */
  static deleteFileContract(
    params: {
      /** Id của chứng từ */
      fileContractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/DeleteFileContract';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fileContractId: params['fileContractId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa sản phẩm
   */
  static deleteContractProduct(
    params: {
      /** Id của hợp đồng sản phẩm */
      contractProductId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/DeleteContractProduct';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { contractProductId: params['contractProductId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteContractTreatment(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/DeleteContractTreatment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportContractTreatment(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      productId?: number;
      /**  */
      userSellId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractTreatmentReportListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Contract/ReportContractTreatment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        productId: params['productId'],
        userSellId: params['userSellId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ContractCommentService {
  /**
   * Thêm/Sửa Comment hợp đồng
   */
  static addOrEditContractComment(
    params: {
      /**  */
      body?: AppAddContractComment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ContractComment/AddOrEditContractComment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa Comment hợp đồng
   */
  static deleteContractComment(
    params: {
      /**  */
      commentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ContractComment/DeleteContractComment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { commentId: params['commentId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách Comment của hợp đồng
   */
  static getListCommentOfContractId(
    params: {
      /**  */
      contractId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppAddContractCommentViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ContractComment/GetListCommentOfContractId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        contractId: params['contractId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerService {
  /**
   * Lấy danh sách KH
   */
  static ajaxLoadCustomer(
    params: {
      /** Tên hoặc SĐT */
      generalSearch?: string;
      /** Trạng thái, all là -1 , active là 1 , khóa là 0 */
      status?: number;
      /** Trang số bao nhiêu */
      pageIndex?: number;
      /** Lấy bao bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/AjaxLoadCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        Status: params['status'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm KH
   */
  static addOrEditCustomer(
    params: {
      /**  */
      body?: CustomerCreate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/AddOrEditCustomer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa khách hàng
   */
  static deleteCustomer(
    params: {
      /** ID KH */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/DeleteCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CustomerID: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Upload chứng từ
   */
  static uploadImage(
    params: {
      /**  */
      customerId?: number;
      /**  */
      image?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/UploadImage';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['customerId']) {
        if (Object.prototype.toString.call(params['customerId']) === '[object Array]') {
          for (const item of params['customerId']) {
            data.append('customerId', item as any);
          }
        } else {
          data.append('customerId', params['customerId'] as any);
        }
      }

      if (params['image']) {
        if (Object.prototype.toString.call(params['image']) === '[object Array]') {
          for (const item of params['image']) {
            data.append('image', item as any);
          }
        } else {
          data.append('image', params['image'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách chứng từ
   */
  static getListImage(
    params: {
      /** ID khách hàng */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/GetListImage';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm danh bạ của khách hàng
   */
  static uploadPhoneBook(
    params: {
      /**  */
      body?: CustomerPhoneBookAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/UploadPhoneBook';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách danh bạ
   */
  static getListPhonebook(
    params: {
      /** ID khách hàng */
      customerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/GetListPhonebook';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tìm kiếm thông tin khách hàng bằng tên
   */
  static getShortInfoCustomer(
    params: {
      /**  */
      customerKey?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Customer/GetShortInfoCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        customerKey: params['customerKey'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CustomerFileExportRequestService {
  /**
   * Tạo yêu cầu xuất hồ sơ
   */
  static customerFileExportRequest(
    params: {
      /**  */
      body?: AppCustomerFileExportRequestAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CustomerFileExportRequest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * đổi trạng thái Yêu cầu xuất hồ sơ
   */
  static changeAction(
    params: {
      /** id của request */
      id?: number;
      /** trạng thái */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CustomerFileExportRequest/ChangeAction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'], status: params['status'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách Yêu cầu xuất hồ sơ
   */
  static listRequestExport(
    params: {
      /** Từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
      /** trạng thái */
      status?: number;
      /** 1 */
      pageIndex?: number;
      /** 10 */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerFileExportRequestViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CustomerFileExportRequest/ListRequestExport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        status: params['status'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách Yêu cầu xuất hồ sơ chưa xử lý
   */
  static listRequestExportNotResolve(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CustomerFileExportRequest/ListRequestExportNotResolve';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DataCustomerService {
  /**
   * Thêm sửa data khách hàng
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppDataCustomerAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thay đổi trạng thái hoặc comment
   */
  static updateStatusOrComment(
    params: {
      /**  */
      body?: AppUpdateStatusOrCommentModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/UpdateStatusOrComment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách comment
   */
  static getComment(
    params: {
      /** Id khách hàng */
      dataCustomerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppGetCommentListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/GetComment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { dataCustomerId: params['dataCustomerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách data khách hàng
   */
  static getDataCustomer(
    params: {
      /** tên khách hàng. rỗng */
      searchName?: string;
      /** sđt. rỗng */
      phone?: string;
      /** Mã nhân viên. -1 tất cả */
      staffId?: number;
      /** Trạng thái. -1 tất cả */
      status?: number;
      /** Mã media. -1 tất cả */
      mediaId?: number;
      /** Mã nhân viên. -1 tất cả */
      telesalesId?: number;
      /** Mã dịch vụ. -1 tất cả */
      serviceId?: number;
      /** Từ ngày. null tất cả */
      fromDate?: string;
      /** Đến ngày. null tất cả */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      isppointment?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetDataCustomerModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/GetDataCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        searchName: params['searchName'],
        phone: params['phone'],
        staffId: params['staffId'],
        status: params['status'],
        mediaId: params['mediaId'],
        telesalesId: params['telesalesId'],
        serviceId: params['serviceId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        isppointment: params['isppointment']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Chia khách hàng cho Telesales
   */
  static changeTelesales(
    params: {
      /**  */
      body?: AppChangeTelesales;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/ChangeTelesales';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Dữ liệu trên trạng thái
   */
  static statusSearch(
    params: {
      /** tên khách hàng. rỗng */
      searchName?: string;
      /** sđt. rỗng */
      phone?: string;
      /** Mã nhân viên. -1 tất cả */
      staffId?: number;
      /** Trạng thái. -1 tất cả */
      status?: number;
      /** Mã media. -1 tất cả */
      mediaId?: number;
      /** Mã nhân viên. -1 tất cả */
      telesalesId?: number;
      /**  */
      serviceId?: number;
      /** Từ ngày. null tất cả */
      fromDate?: string;
      /** Đến ngày. null tất cả */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/StatusSearch';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        searchName: params['searchName'],
        phone: params['phone'],
        staffId: params['staffId'],
        status: params['status'],
        mediaId: params['mediaId'],
        telesalesId: params['telesalesId'],
        serviceId: params['serviceId'],
        fromDate: params['fromDate'],
        toDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hẹn giờ khách hàng
   */
  static changeTimer(
    params: {
      /**  */
      body?: AppChangeTimer;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/ChangeTimer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách data khách hàng hoom nay
   */
  static getDataCustomerToday(
    params: {
      /** tên khách hàng. rỗng */
      searchName?: string;
      /** sđt. rỗng */
      phone?: string;
      /** Mã nhân viên. -1 tất cả */
      staffId?: number;
      /** Trạng thái. -1 tất cả */
      status?: number;
      /** Mã media. -1 tất cả */
      mediaId?: number;
      /** Mã nhân viên. -1 tất cả */
      telesalesId?: number;
      /**  */
      serviceId?: number;
      /** Từ ngày. null tất cả */
      fromDate?: string;
      /** Đến ngày. null tất cả */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetDataCustomerModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/GetDataCustomerToday';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        searchName: params['searchName'],
        phone: params['phone'],
        staffId: params['staffId'],
        status: params['status'],
        mediaId: params['mediaId'],
        telesalesId: params['telesalesId'],
        serviceId: params['serviceId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa Data
   */
  static delete(
    params: {
      /** Mã KH */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/Delete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static calculateMoneyTelesale(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/CalculateMoneyTelesale';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách data khách hàng hẹn gọi lại
   */
  static getDataCustomerReCall(options: IRequestOptions = {}): Promise<GetDataCustomerModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DataCustomer/GetDataCustomerReCall';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FileService {
  /**
   * Upload chứng từ
   */
  static uploadImage(
    params: {
      /**  */
      image?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/File/UploadImage';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['image']) {
        if (Object.prototype.toString.call(params['image']) === '[object Array]') {
          for (const item of params['image']) {
            data.append('image', item as any);
          }
        } else {
          data.append('image', params['image'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class GeneralCommentService {
  /**
   * Thêm bình luận
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppGeneralCommentAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/GeneralComment/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách bình luận
   */
  static getList(
    params: {
      /** Loại: 1 media, 2 campain */
      typeId?: number;
      /** Mã Media */
      mediaId?: number;
      /** Mã Campain */
      campainId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralCommentViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/GeneralComment/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { typeId: params['typeId'], mediaId: params['mediaId'], campainId: params['campainId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MarketingService {
  /**
   * Tạo đơn marketing
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppMarketingAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Đổi trạng thái
   */
  static changeStatus(
    params: {
      /** id của đơn marketing */
      marketingId?: number;
      /** trạng thái của đơn marketing */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/ChangeStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { marketingId: params['marketingId'], status: params['status'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách đơn marketing
   */
  static listMarketingOrders(
    params: {
      /** Từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
      /** trạng thái */
      status?: number;
      /** loại action */
      actionId?: number;
      /** từ khóa tìm kiếm */
      keySearch?: string;
      /** 1 */
      pageIndex?: number;
      /** 10 */
      pageSize?: number;
      /** id NHÂN VIÊN TELESALE */
      staffId?: number;
      /** id ads */
      adsId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MarketingViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/ListMarketingOrders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        status: params['status'],
        actionId: params['actionId'],
        keySearch: params['keySearch'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize'],
        staffID: params['staffId'],
        adsId: params['adsId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * sELECT CHO TRANG DANH SÁCH
   */
  static statusSearch(
    params: {
      /** Từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
      /** từ khóa tìm kiếm */
      keySearch?: string;
      /** từ khóa tìm kiếm */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/StatusSearch';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        keySearch: params['keySearch'],
        staffID: params['staffId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Chuyển các hành động
   */
  static changeAction(
    params: {
      /**  */
      body?: AppMarketingActionComment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/ChangeAction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm sửa ADS
   */
  static createOrUpdateAds(
    params: {
      /**  */
      body?: AppAdsAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/CreateOrUpdateADS';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sachs ADS
   */
  static listAds(
    params: {
      /**  */
      adsName?: string;
      /**  */
      status?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/ListADS';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        adsName: params['adsName'],
        status: params['status'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa ADS
   */
  static deleteAds(
    params: {
      /** ID bản ghi */
      adsId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/DeleteAds';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { adsID: params['adsId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm tạo cấu hình trạng thái
   */
  static createOrUpdateConfigMaketingStatus(
    params: {
      /**  */
      body?: AppConfigMarketingStatus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/CreateOrUpdateConfigMaketingStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa cấu hình trạng thái
   */
  static deleteConfigMaketingStatus(
    params: {
      /** ID bản ghi */
      configMaketingStatusId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/DeleteConfigMaketingStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { configMaketingStatusID: params['configMaketingStatusId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sachs ADS
   */
  static listConfigMaketingStatus(
    params: {
      /**  */
      name?: string;
      /**  */
      status?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/ListConfigMaketingStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        status: params['status'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tạo phiếu duyệt chi marketing
   */
  static createOrUpdateExpenseExpected(
    params: {
      /**  */
      body?: AppCreateCollectExpenseExpectMarketing;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/CreateOrUpdateExpenseExpected';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo ADS
   */
  static reportAds(
    params: {
      /** ô tìm theo ADS */
      name?: string;
      /** từ ngày dd/MM/yyyy để mặc định đầu tháng */
      fromDate?: string;
      /** đến ngày dd/MM/yyyy để mặc định cuối tháng */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Marketing/ReportADS';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Name: params['name'], fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MediaService {
  /**
   * Thêm sửa Media
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppMediaAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Media/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa Media
   */
  static delete(
    params: {
      /** Mã media */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Media/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách Media
   */
  static getMediaDataCustomer(
    params: {
      /** PageIndex */
      pageIndex?: number;
      /** PageSize */
      pageSize?: number;
      /** Mã nhân viên. -1 tất cả */
      staffId?: number;
      /** Mã dịch vụ. -1 tất cả */
      serviceId?: number;
      /** Từ ngày.  null tất cả */
      fromDate?: string;
      /** Đến ngày.   null tất cả */
      toDate?: string;
      /** Trạng thái. -1 tất cả */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppReportMediaDataCustomerListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Media/GetMediaDataCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        staffId: params['staffId'],
        serviceId: params['serviceId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        status: params['status']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Dữ liệu trên trạng thái
   */
  static statusSearch(
    params: {
      /** Mã nhân viên. -1 tất cả */
      staffId?: number;
      /** Mã dịch vụ. -1 tất cả */
      serviceId?: number;
      /** Từ ngày.  null tất cả */
      fromDate?: string;
      /** Đến ngày.   null tất cả */
      toDate?: string;
      /** Trạng thái. -1 tất cả */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Media/StatusSearch';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        staffId: params['staffId'],
        serviceId: params['serviceId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        status: params['status']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thay đổi trạng thái hoặc comment
   */
  static updateStatusOrComment(
    params: {
      /**  */
      body?: AppUpdateStatusOrCommentMedia;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Media/UpdateStatusOrComment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách comment
   */
  static getComment(
    params: {
      /** Id Media */
      meidaId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppGetCommentMediaListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Media/GetComment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { meidaId: params['meidaId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MetaService {
  /**
   * Meta -  Danh sách nhân viên ( không có tài khoản chủ )
   */
  static getAllStaff(options: IRequestOptions = {}): Promise<MetaStaffListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy tài khoản của cửa hàng
   */
  static getAllUser(options: IRequestOptions = {}): Promise<MetaStaffListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta - danh sách ngân hàng
   */
  static getAllBank(options: IRequestOptions = {}): Promise<MetaBankListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllBank';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta - danh sách thành phố
   */
  static getAllCity(options: IRequestOptions = {}): Promise<MetaCityListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllCity';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta - danh sách Quận/Huyện
   */
  static getDistrict(
    params: {
      /** Id thành phố */
      cityId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MetaDistrictListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetDistrict';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cityId: params['cityId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy tài khoản của cửa hàng theo groupId
   */
  static getAllUserByGroup(
    params: {
      /** Id group */
      groupId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MetaStaffByGroupListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllUserByGroup';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { groupId: params['groupId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách nguồn khách
   */
  static getSourceCustomer(options: IRequestOptions = {}): Promise<SelectModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetSourceCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách trạng thái của hợp đồng
   */
  static getStatusContract(options: IRequestOptions = {}): Promise<SelectModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetStatusContract';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách nhân viên (không có tài khoản chủ cửa hàng)
   */
  static getStaffAll(options: IRequestOptions = {}): Promise<MetaStaffByGroupListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetStaffAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách ví của cửa hàng
   */
  static getWalletAll(options: IRequestOptions = {}): Promise<MetaWalletListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetWalletAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách hình thức lãi của góp vốn
   */
  static getTypeRateCapital(options: IRequestOptions = {}): Promise<SelectModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetTypeRateCapital';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách campain
   */
  static getCampainAll(options: IRequestOptions = {}): Promise<MetaCampainListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetCampainAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách nguồn khách
   */
  static getSourceCustomerAll(options: IRequestOptions = {}): Promise<MetaSourceCustomerListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetSourceCustomerAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  trạng thái marketing
   */
  static getStatusMaketing(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetStatusMaketing';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách ADS
   */
  static getAdsAll(options: IRequestOptions = {}): Promise<MetaAdsListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAdsAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách Media
   */
  static getMediaAll(
    params: {
      /** Mã nhân viên -1 tất cả */
      mediaUserId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MetaMediaListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetMediaAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mediaUserId: params['mediaUserId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
 *  Meta -  Danh sách Vị trí
<param name="departmentId">Id phòng ban</param>
 */
  static getPositionAll(
    params: {
      /**  */
      departmentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MetaPositionListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetPositionAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { departmentId: params['departmentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách phòng ban
   */
  static getDepartmentAll(options: IRequestOptions = {}): Promise<MetaDepartmentListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetDepartmentAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy tài khoản của cửa hàng theo phòng ban
   */
  static getAllUserByDepartment(
    params: {
      /** Id phòng ban */
      departmentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppStaffMetaListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllUserByDepartment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { departmentId: params['departmentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy tài khoản của cửa hàng theo vị trí
   */
  static getAllUserByPosition(
    params: {
      /** Id vị trí */
      positionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppStaffMetaListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetAllUserByPosition';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { positionId: params['positionId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách Data khách hàng theo trạng thái
   */
  static getDataCustomerStatus(
    params: {
      /** trạng thái */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MetaDataCustomerListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetDataCustomerStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { status: params['status'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách Data khách hàng
   */
  static getDataCustomerAll(options: IRequestOptions = {}): Promise<MetaDataCustomerListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetDataCustomerAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Meta -  Danh sách HĐ
   */
  static getContractId(options: IRequestOptions = {}): Promise<MetaContractListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Meta/GetContractId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PaymentService {
  /**
   * DS Cấu hình phiếu thu chi khác
   */
  static getConfigCollectionExpense(
    params: {
      /** Tên phiếu */
      name?: string;
      /** -1 : tất cả ,  20 phiếu thu , 105 : phiếu chi */
      type?: number;
      /** -1 tất cả ,1  hoạt động , 0 khóas */
      status?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppConfigCollectExpenseListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/GetConfigCollectionExpense';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        type: params['type'],
        status: params['status'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm mới cấu hình
   */
  static createOrUpdateConfig(
    params: {
      /**  */
      body?: AppCreateConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/CreateOrUpdateConfig';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm mới phiếu thu
   */
  static payCollectOther(
    params: {
      /**  */
      body?: AppCreateCollectExpense;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/PayCollectOther';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm mới phiếu chi
   */
  static payExpenseOther(
    params: {
      /**  */
      body?: AppCreateCollectExpense;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/PayExpenseOther';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy phiếu thu chi
   */
  static cancelPaymentExpenseCollection(
    params: {
      /** ID của bản ghi */
      paymentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/CancelPaymentExpenseCollection';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { paymentID: params['paymentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * DS phiếu thu
   */
  static detailCollect(
    params: {
      /** Tên,SĐT */
      generalSearch?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /** loại phiếu chi tiết */
      detailType?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCashBookDataListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/DetailCollect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        generalSearch: params['generalSearch'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        detailType: params['detailType']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * DS phiếu chi
   */
  static detailExpense(
    params: {
      /** Tên,SĐT */
      generalSearch?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /** loại phiếu chi tiết */
      detailType?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCashBookDataListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/DetailExpense';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        generalSearch: params['generalSearch'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        detailType: params['detailType']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy loại phiếu thu chi
   */
  static getConfigByType(
    params: {
      /** type = 20 là phiếu thu, 105 phiếu chi */
      type?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/GetConfigByType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { type: params['type'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm Đề xuất dự chi
   */
  static createOrUpdateExpenseExpected(
    params: {
      /**  */
      body?: AppCreateCollectExpenseExpect;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/CreateOrUpdateExpenseExpected';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách quyền Phiếu của nhân viên
   */
  static getPermissionCollectExpenseStaff(
    params: {
      /** Id nhân viên lấy từ select box */
      staffId?: number;
      /** Loại phiếu */
      typeExpected?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JSTreeModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/GetPermissionCollectExpenseStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { StaffID: params['staffId'], typeExpected: params['typeExpected'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm quyền Phiếu cho nhân viên
   */
  static addPermissionCollectExpenseAsync(
    params: {
      /**  */
      body?: AppAddPermission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/AddPermissionCollectExpenseAsync';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy quyền phiếu của user đăng nhập
   */
  static getPermissionCollectExpenseUser(
    params: {
      /** loại phiếu type = 20 là phiếu thu, 105 phiếu chi */
      typeExpected?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserPermissionCollectExpenseExpectListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/GetPermissionCollectExpenseUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { typeExpected: params['typeExpected'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * GetDebtMoney
   */
  static getDebtMoney(
    params: {
      /**  */
      contractId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/GetDebtMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractId: params['contractId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách phiếu dự chi
   */
  static getListCollectExpense(
    params: {
      /** 1 là phiếu thu, 2 là phiếu chi */
      typeCollectExpense?: number;
      /** 1 chờ duyệt, 2 Đã hoàn thành */
      status?: number;
      /** truyền lên -1 là lấy all, truyền lên 2 để lấy ở màn marketing */
      sourceExpense?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/GetListCollectExpense';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        typeCollectExpense: params['typeCollectExpense'],
        status: params['status'],
        sourceExpense: params['sourceExpense'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Duyệt/Hủy dự chi
   */
  static changeStatusCollectExpense(
    params: {
      /** Id của dự chi */
      collectExpenseId?: number;
      /** 2 là duyệt, -11 là hủy */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/ChangeStatusCollectExpense';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { collectExpenseId: params['collectExpenseId'], status: params['status'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * DS phiếu thu marketing
   */
  static detailCollectStaff(
    params: {
      /** Tên,SĐT */
      generalSearch?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /** loại phiếu chi tiết */
      detailType?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCashBookDataListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/DetailCollectStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        generalSearch: params['generalSearch'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        detailType: params['detailType']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * DS phiếu chi marketing
   */
  static detailExpenseStaff(
    params: {
      /** Tên,SĐT */
      generalSearch?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /** loại phiếu chi tiết */
      detailType?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppCashBookDataListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Payment/DetailExpenseStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        generalSearch: params['generalSearch'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        detailType: params['detailType']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PaymentCampainService {
  /**
   * Thêm sửa phiếu chi
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppPaymentCampainAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PaymentCampain/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Duyệt phiếu chi
   */
  static approved(
    params: {
      /**  */
      body?: AppApproved;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PaymentCampain/Approved';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy phiếu chi
   */
  static cancel(
    params: {
      /** Mã phiếu chi */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PaymentCampain/Cancel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách phiếu chi
   */
  static getList(
    params: {
      /** 1: chiến dịch,  2: khách hàng, 3: Media, 5: Hợp đồng, 6: sản phẩm */
      source?: number;
      /** pageIndex */
      pageIndex?: number;
      /** pageSize */
      pageSize?: number;
      /** từ ngày (null) tất cả */
      fromDate?: string;
      /** đến ngày (null) tất cả */
      toDate?: string;
      /** loại chi -1 tất cả */
      actionId?: number;
      /** loại phiếu -1 tất cả */
      typeId?: number;
      /** trạng thái -1 tất cả */
      status?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppListPaymentCampainListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PaymentCampain/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        source: params['source'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        actionId: params['actionId'],
        typeId: params['typeId'],
        status: params['status']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách chờ duyệt
   */
  static pendingApproved(
    params: {
      /** pageIndex */
      pageIndex?: number;
      /** pageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppListPaymentCampainListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PaymentCampain/PendingApproved';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { pageIndex: params['pageIndex'], pageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static summaryDataMoney(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PaymentCampain/SummaryDataMoney';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ProductService {
  /**
   * Thêm sửa sản phẩm
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppProductAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Product/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách sản phẩm
   */
  static getList(
    params: {
      /** Tên sản phẩm */
      searchName?: string;
      /** Mã sản phẩm */
      searchCode?: string;
      /** từ ngày */
      fromDate?: string;
      /** tới ngày */
      toDate?: string;
      /** pageIndex */
      pageIndex?: number;
      /** pageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Product/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        searchName: params['searchName'],
        searchCode: params['searchCode'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa sản phẩm
   */
  static delete(
    params: {
      /** Mã sản phẩm */
      productId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Product/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { productId: params['productId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * danh sách sản phẩm meta
   */
  static getProductMeta(options: IRequestOptions = {}): Promise<SelectProductListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Product/GetProductMeta';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm sửa nhập hàng
   */
  static addOrEditImportProduct(
    params: {
      /**  */
      body?: AppImportProductAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Product/AddOrEditImportProduct';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa nhập hàng
   */
  static deleteImportProduct(
    params: {
      /** Mã nhận hàng */
      importProductId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Product/DeleteImportProduct';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { importProductId: params['importProductId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách nhập hàng
   */
  static getListImportProduct(
    params: {
      /** mã sản phẩm (-1 tất cả) */
      productId?: number;
      /** mã nhân viên (-1 tất cả) */
      staffId?: number;
      /** từ ngày */
      fromDate?: string;
      /** tới ngày */
      toDate?: string;
      /** pageIndex */
      pageIndex?: number;
      /** pageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImportProductViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Product/GetListImportProduct';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        productId: params['productId'],
        staffId: params['staffId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ReportService {
  /**
   * BẢNG TỔNG KẾT
   */
  static summaryDebt(
    params: {
      /** Tất cả ví -100 */
      bankcardId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppSummaryCashBookResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/SummaryDebt';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { bankcardID: params['bankcardId'], fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * chi tiết api
   */
  static detailCashBook(
    params: {
      /**  */
      bankcardId?: number;
      /**  */
      codeText?: string;
      /**  */
      generalSearch?: string;
      /**  */
      typeId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/DetailCashBook';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        bankcardID: params['bankcardId'],
        codeText: params['codeText'],
        generalSearch: params['generalSearch'],
        typeID: params['typeId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Chi tiết phí dịch vụ
   */
  static serviceFeeDetails(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /** Mã dịch vụ */
      sourceServiceId?: number;
      /** Mã nhân viên */
      staffId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceFeeDetailsModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ServiceFeeDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        sourceServiceId: params['sourceServiceId'],
        staffId: params['staffId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo sản phẩm
   */
  static product(
    params: {
      /** mã sản phẩm */
      productId?: number;
      /** -1: tất cả, 1: nhập, 2: xuất */
      status?: number;
      /** từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
      /** pageIndex */
      pageIndex?: number;
      /** pageSize */
      pageSize?: number;
      /**  */
      generalSearch?: string;
      /**  */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportProductListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/Product';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        productId: params['productId'],
        status: params['status'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        generalSearch: params['generalSearch'],
        staffID: params['staffId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo tổng sản phẩm
   */
  static totalProduct(
    params: {
      /** mã sản phẩm */
      productId?: number;
      /** -1: tất cả, 1: nhập, 2: xuất */
      status?: number;
      /** từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
      /** generalSearch */
      generalSearch?: string;
      /** staffID */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportTotalProductResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/TotalProduct';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        productId: params['productId'],
        status: params['status'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        generalSearch: params['generalSearch'],
        staffID: params['staffId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo marketing
   */
  static marketing(
    params: {
      /**  */
      campainId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportMarketingResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/Marketing';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        campainID: params['campainId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        staffID: params['staffId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo marketing
   */
  static reportSale(
    params: {
      /**  */
      campainId?: number;
      /**  */
      fromDate?: string;
      /**  */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportSaleResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/ReportSale';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { campainID: params['campainId'], fromDate: params['fromDate'], staffID: params['staffId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo thu chi
   */
  static transactionExpense(
    params: {
      /** từ ngày */
      fromDate?: string;
      /** đến ngày */
      toDate?: string;
      /** mã dịch vụ: tất cả -1 */
      serviceId?: number;
      /** nhân viên: tất cả -1 */
      staffId?: number;
      /** bộ phận: tất cả -1 */
      departmentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportTransactionExpenseListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/TransactionExpense';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        serviceId: params['serviceId'],
        staffId: params['staffId'],
        departmentId: params['departmentId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo Home - Telesales
   */
  static homeTelesales(
    params: {
      /**  */
      staffId?: number;
      /**  */
      serviceId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportTelesaleHomeListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/HomeTelesales';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        staffID: params['staffId'],
        serviceID: params['serviceId'],
        fromDate: params['fromDate'],
        toDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo Home - Sales
   */
  static homeSales(
    params: {
      /**  */
      staffId?: number;
      /**  */
      serviceId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HomeSaleReportListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/HomeSales';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        staffID: params['staffId'],
        serviceID: params['serviceId'],
        fromDate: params['fromDate'],
        toDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo Home - ADS
   */
  static homeAds(
    params: {
      /**  */
      staffId?: number;
      /**  */
      serviceId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HomeReportAdsListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/HomeADS';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        staffID: params['staffId'],
        serviceID: params['serviceId'],
        fromDate: params['fromDate'],
        toDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo Home - Services
   */
  static homeServices(
    params: {
      /**  */
      serviceId?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportHomeServiceListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/HomeServices';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { serviceID: params['serviceId'], fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Báo cáo Home - Shop
   */
  static homeShop(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportHomeServiceListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Report/HomeShop';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ShopService {
  /**
   * Danh sách chi nhánh
   */
  static ajaxLoadShop(
    params: {
      /** Tên cửa hàng */
      shopName?: string;
      /** Trạng thái : 1 hoạt động , -1 tất cả , 0 tạm ngưng */
      status?: number;
      /** PageIndex */
      pageIndex?: number;
      /** PageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppShopViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Shop/AjaxLoadShop';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ShopName: params['shopName'],
        Status: params['status'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm sửa chi nhánh
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppShopAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Shop/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa chi nhánh
   */
  static delete(
    params: {
      /** ID cửa hàng bị xóa */
      shopIdDelete?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Shop/Delete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ShopIDDelete: params['shopIdDelete'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách cửa hàng cho chuyển cửa hàng
   */
  static getShopActive(options: IRequestOptions = {}): Promise<AppShopViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Shop/GetShopActive';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class SourceCustomerService {
  /**
   * Thêm sửa nguồn khách
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppSourceCustomerAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SourceCustomer/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa nguồn khách
   */
  static delete(
    params: {
      /** Mã nguồn khách */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SourceCustomer/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách nguồn khách
   */
  static getList(
    params: {
      /** PageIndex */
      pageIndex?: number;
      /** PageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SourceCustomerViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SourceCustomer/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { pageIndex: params['pageIndex'], pageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class StatusGeneralService {
  /**
   * Thêm sửa trạng thái
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppStatusGeneralAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/StatusGeneral/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa cấu hình
   */
  static delete(
    params: {
      /** Mã trạng thái */
      statusGeneralId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/StatusGeneral/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { statusGeneralId: params['statusGeneralId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách trạng thái
   */
  static getList(
    params: {
      /** Loại 1: telesales, 2: media, 3: contract */
      typeId?: number;
      /** tên trạng thái */
      name?: string;
      /** trạng thái: -1 tất cả */
      status?: number;
      /** pageIndex */
      pageIndex?: number;
      /** pageSize */
      pageSize?: number;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppStatusGeneralListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/StatusGeneral/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        typeId: params['typeId'],
        name: params['name'],
        status: params['status'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        fromDate: params['fromDate'],
        toDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class TransactionService {
  /**
   * Tạo phiếu thu
   */
  static createCollect(
    params: {
      /**  */
      body?: AppTransactionAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Transaction/CreateCollect';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tạo phiếu chi
   */
  static createExpense(
    params: {
      /**  */
      body?: AppTransactionAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Transaction/CreateExpense';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy phiếu thu
   */
  static cancelCollect(
    params: {
      /** Mã giao dịch */
      transactionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Transaction/CancelCollect';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { transactionId: params['transactionId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Hủy phiếu chi
   */
  static cancelExpense(
    params: {
      /** Mã giao dịch */
      transactionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Transaction/CancelExpense';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { transactionId: params['transactionId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách log
   */
  static getLog(
    params: {
      /** Mã giao dịch */
      transactionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppGetLogTransactionListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Transaction/GetLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { transactionId: params['transactionId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy danh sách phiếu thu, chi
   */
  static getList(
    params: {
      /** Tên KH */
      searchName?: string;
      /** 1 phiếu thu, 2 phiếu chi */
      paymentTypeId?: number;
      /** Trạng thái -1 tất cả */
      status?: number;
      /** Ví -1 tất cả */
      walletId?: number;
      /** Loại phiếu -1 tất cả */
      typeId?: number;
      /** Từ ngày null tất cả */
      fromDate?: string;
      /** Đến ngày null tất cả */
      toDate?: string;
      /** Chi phân bổ, Chi mục tiêu -1 tất cả */
      actionPaymentId?: number;
      /** pageIndex */
      pageIndex?: number;
      /** pageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Transaction/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        searchName: params['searchName'],
        paymentTypeId: params['paymentTypeId'],
        status: params['status'],
        walletId: params['walletId'],
        typeId: params['typeId'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        actionPaymentId: params['actionPaymentId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   * Đăng nhập hệ thống
   */
  static login(
    params: {
      /**  */
      body?: APIUserRequestLogin;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<APIUserLoginResponseResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách nhân viên
   */
  static ajaxLoadStaff(
    params: {
      /** Tên tài khoản */
      userName?: string;
      /** Trạng thái : -1: Tất cả, 1: làm việc, 2: nghỉ */
      status?: number;
      /** Phòng ban -1 tất cả */
      departmentId?: number;
      /** Vị trí -1 tất cả */
      positionId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppUserViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/AjaxLoadStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        userName: params['userName'],
        status: params['status'],
        departmentId: params['departmentId'],
        positionId: params['positionId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa nhân viên
   */
  static deleteStaff(
    params: {
      /**  */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/DeleteStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { StaffID: params['staffId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách quyền của nhân viên
   */
  static getPermissionStaff(
    params: {
      /** Id nhân viên lấy từ select box */
      staffId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JSTreeModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/GetPermissionStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { StaffID: params['staffId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm quyền cho nhân viên
   */
  static addPermissionStaff(
    params: {
      /**  */
      body?: AppAddPermission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/AddPermissionStaff';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm nhân viên
   */
  static addOrEditStaff(
    params: {
      /**  */
      body?: AppAddStaff;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/AddOrEditStaff';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thay đổi mật khẩu
   */
  static changePassword(
    params: {
      /**  */
      body?: AppChangePassword;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/ChangePassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thay đổi thông tin
   */
  static changeInfomation(
    params: {
      /**  */
      body?: AppChangeInfomation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/ChangeInfomation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách Cộng tác viên theo filter username và trạng thái
   */
  static getListAffByFilter(
    params: {
      /**  */
      keySearch?: string;
      /**  */
      status?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AffInforViewModelListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/GetListAffByFilter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        keySearch: params['keySearch'],
        status: params['status'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm/Sửa Cộng Tác Viên
   */
  static addOrEditAff(
    params: {
      /**  */
      body?: AppAddAff;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/AddOrEditAff';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa Cộng Tác Viên
   */
  static deleteAff(
    params: {
      /**  */
      affId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/DeleteAff';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { affId: params['affId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách chủ cửa hàng
   */
  static listShopOwner(
    params: {
      /** Tên tài khoản */
      userName?: string;
      /** Trạng thái : -1: Tất cả, 1: hoạt động, 2: đã khóa */
      status?: number;
      /** Nhóm tài khoản : -1: Tất cả */
      groupId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppUserShopOwnerViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/ListShopOwner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        userName: params['userName'],
        status: params['status'],
        groupId: params['groupId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm chủ cửa hàng
   */
  static addOrEditShopOwner(
    params: {
      /**  */
      body?: AppAddShopOwner;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/AddOrEditShopOwner';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Cấu hình nhân viên và ví
   */
  static configUserWallet(
    params: {
      /**  */
      body?: AppUserWalletAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/ConfigUserWallet';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
 * Lấy Cấu hình nhân viên và ví
<param name="userId"> mã nhân viên</param>
 */
  static getConfigUserWallet(
    params: {
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/GetConfigUserWallet';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class UserV2Service {
  /**
   * Thêm sửa nhân viên ver 2
   */
  static addOrEditStaff(
    params: {
      /**  */
      body?: AppCreateStaffAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserV2/AddOrEditStaff';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thêm sửa chủ cửa hàng ver 2
   */
  static addOrEditOwner(
    params: {
      /**  */
      body?: AppCreateOwnerAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserV2/AddOrEditOwner';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa tài khoản
   */
  static delete(
    params: {
      /** Mã nhân viên */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserV2/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách chủ cửa hàng ver 2
   */
  static getListOwner(
    params: {
      /** Tên tài khoản */
      userName?: string;
      /** Trạng thái : -1: Tất cả, 1: hoạt động, 2: đã khóa */
      status?: number;
      /** Nhóm tài khoản : -1: Tất cả */
      groupId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppUserShopOwnerViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserV2/GetListOwner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        userName: params['userName'],
        status: params['status'],
        groupId: params['groupId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách nhân viên
   */
  static getListStaff(
    params: {
      /** Tên tài khoản */
      userName?: string;
      /** Trạng thái : -1: Tất cả, 1: làm việc, 2: nghỉ */
      status?: number;
      /** Phòng ban -1 tất cả */
      departmentId?: number;
      /** Vị trí -1 tất cả */
      positionId?: number;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppUserStaffViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserV2/GetListStaff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        userName: params['userName'],
        status: params['status'],
        departmentId: params['departmentId'],
        positionId: params['positionId'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách nhân viên của cửa hàng
   */
  static getListStaffByShop(options: IRequestOptions = {}): Promise<AppStaffViewMetaListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserV2/GetListStaffByShop';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách nhân viên mình quản lý
   */
  static getListStaffManager(options: IRequestOptions = {}): Promise<AppStaffViewMetaListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserV2/GetListStaffManager';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class WalletService {
  /**
   * Thêm sửa ví
   */
  static addOrEdit(
    params: {
      /**  */
      body?: AppWalletAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Wallet/AddOrEdit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Xóa ví
   */
  static delete(
    params: {
      /** ID ví bị xóa */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Wallet/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách ví
   */
  static getWallet(
    params: {
      /** Tên ví */
      searchName?: string;
      /** Trạng thái : 1 hoạt động , -1 tất cả , 0 đã khóa */
      status?: number;
      /** PageIndex */
      pageIndex?: number;
      /** PageSize */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppWalletViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Wallet/GetWallet';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        searchName: params['searchName'],
        status: params['status'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách ví của tất cả cửa hàng
   */
  static getWalletAllShop(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Wallet/GetWalletAllShop';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Danh sách ví theo nhân viên đăng nhập
   */
  static getWalletByUser(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Wallet/GetWalletByUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Quỹ còn lại trong màn hình ADS
   */
  static getMoney(
    params: {
      /** Không chọn thì truyền -1 */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Wallet/GetMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Tiền trong ví của tài khoản đăng nhập
   */
  static getMoneyUser(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Wallet/GetMoneyUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy ví theo cửa hàng
   */
  static getWalletByShop(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Wallet/GetWalletByShop';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy ví theo tài khoản đăng nhập
   */
  static getWalletLogin(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Wallet/GetWalletLogin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Rút tiền trong ví của chủ cửa hàng
   */
  static withdrawMoney(
    params: {
      /**  */
      body?: AppWithdrawMoney;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Wallet/WithdrawMoney';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WalletTransferService {
  /**
   * Thêm mới điều chuyển ví
   */
  static createAsync(
    params: {
      /**  */
      body?: AppWalletTransferAdd;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WalletTransfer/CreateAsync';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Thay đổi trạng thái của điều chuyển ví
   */
  static changeStatus(
    params: {
      /**  */
      body?: AppWalletTransferChangeStatus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WalletTransfer/ChangeStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy ra danh sách chờ xác nhận
   */
  static getListPending(options: IRequestOptions = {}): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WalletTransfer/GetListPending';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy ra danh sách theo mã cửa hàng
   */
  static getListByShopId(
    params: {
      /** mã cửa hàng */
      shopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WalletTransfer/GetListByShopId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { shopId: params['shopId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Lấy ra danh sách lịch sử điều chuyển ví
   */
  static getList(
    params: {
      /** Từ ngày. null tất cả */
      fromDate?: string;
      /** Đến ngày. null tất cả */
      toDate?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
      /**  */
      toWalletId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WalletTransferViewListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WalletTransfer/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageIndex: params['pageIndex'],
        pageSize: params['pageSize'],
        toWalletID: params['toWalletId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export interface APIUserLoginResponse {
  /** ID cửa hàng */
  ShopID?: number;

  /** Tên cửa hàng */
  ShopName?: string;

  /**  */
  lstShop?: ShopUserLogin[];

  /** UserID */
  UserID?: number;

  /** UserName */
  UserName?: string;

  /** Tên KH */
  FullName?: string;

  /** SĐT */
  Phone?: string;

  /** HẾt hạn */
  ExpiredDate?: Date;

  /** Token */
  Token?: string;

  /** Email */
  Email?: string;

  /** GroupID */
  GroupID?: number;

  /** WalletId */
  WalletId?: number;

  /**  */
  menuPermissions?: MenuPermission[];
}

export interface APIUserLoginResponseResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: APIUserLoginResponse;
}

export interface APIUserRequestLogin {
  /** Tên đăng nhập */
  UserName?: string;

  /** Mật khẩu */
  Password?: string;
}

export interface AffInforViewModel {
  /**  */
  AffID?: number;

  /**  */
  AffCode?: string;

  /**  */
  Address?: string;

  /**  */
  ShopID?: number;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;
}

export interface AffInforViewModelListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AffInforViewModel[];
}

export interface AppAddAff {
  /** ID Aff : 0 là tạo mới > 0 là edit */
  AffID?: number;

  /** Mã Cộng Tác Viên */
  AffCode?: string;

  /** Địa chỉ */
  Address?: string;

  /** Trạng thái 1 : hoạt động , 0 là khóa */
  Status?: number;

  /** Ghi chú */
  Note?: string;
}

export interface AppAddContractComment {
  /** Id của comment */
  CommentId?: number;

  /** Id của hợp đồng */
  ContractId?: number;

  /** Nội dung */
  Content?: string;
}

export interface AppAddContractCommentView {
  /** Id của comment */
  Id?: number;

  /** Id của hợp đồng */
  ContractId?: number;

  /** Nội dung */
  Content?: string;

  /** Ngày tạo */
  CreatedAt?: Date;

  /** Ngày cập nhật */
  UpdatedAt?: Date;

  /** Người tạo */
  CreatedFullName?: string;
}

export interface AppAddContractCommentViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppAddContractCommentView[];
}

export interface AppAddMoney {
  /** Mã Id */
  Id?: number;

  /** Số tiền */
  Money?: number;

  /** Ghi chú */
  Note?: string;
}

export interface AppAddPermission {
  /** List quyền được xử lý */
  lstPermission?: number[];

  /** ID nhân viên */
  StaffID?: number;

  /** Loại phiếu: 20: phiếu thu, 105: phiếu chi */
  TypeId?: number;
}

export interface AppAddShopOwner {
  /** ID : 0 là tạo mới > 0 là cập nhập */
  UserId?: number;

  /** Tài khoản */
  UserName?: string;

  /** Mật khẩu */
  Password?: string;

  /** Tên */
  FullName?: string;

  /** SĐT */
  Phone?: string;

  /** Ghi chú */
  Note?: string;

  /** Trạng thái */
  Status?: number;

  /** Tên cửa hàng */
  ShopName?: string;
}

export interface AppAddStaff {
  /** ID user : 0 là tạo mới >0 là edit */
  UserID?: number;

  /** Tên tài khoản */
  UserName?: string;

  /** Mật khẩu */
  Password?: string;

  /** Tên */
  FullName?: string;

  /** SĐt */
  Phone?: string;

  /** Email */
  Email?: string;

  /** Trạng thái 1 : hoạt động , 0 là khóa */
  Status?: number;

  /** Id của cửa hàng */
  ShopID?: number;

  /** Id phòng ban */
  DepartmentId?: number;

  /** Id Vị trí */
  PositionId?: number;
}

export interface AppAdsAdd {
  /** ID bản ghi = 0 là tạo */
  AdsID?: number;

  /** Tên ADS */
  AdsName?: string;

  /** ID nhân viên Marketing */
  StaffID?: number;

  /** Trạng thái 1 là hoạt động , 0 là khóa */
  Status?: number;

  /** tiền giao trong ví */
  TotalMoney?: number;

  /** ID ví */
  WalletID?: number;
}

export interface AppApproved {
  /** Id */
  Id?: number;
}

export interface AppCampainActionAdd {
  /** Mã ID. 0: tạo mới, >0: cập nhập */
  Id?: number;

  /** Id chiến dịch */
  CampainId?: number;

  /** Số lượng tin nhắn */
  TotalMessage?: number;

  /** Số tiền chi tiêu */
  AmountSpent?: number;

  /** Ghi chú */
  Note?: string;
}

export interface AppCampainAdd {
  /** Mã chiến dịch. 0: tạo mới, >0: cập nhập */
  Id?: number;

  /** Id Media */
  MediaId?: number;
}

export interface AppCapitalAdd {
  /** Loại người góp : 1 mới , 2 cũ */
  TypePeopleCapital?: number;

  /** Mã HĐ. 0: tạo mới, >0: cập nhập */
  Id?: number;

  /** Mã ví */
  WalletId?: number;

  /** Họ và tên */
  FullName?: string;

  /** SĐT */
  Phone?: string;

  /** Số tiền */
  Money?: number;

  /** Ngày góp */
  DonatedAt?: Date;

  /** Ghi chú */
  Note?: string;

  /** Hình thức lãi */
  TypeRate?: number;

  /** thu lãi trước (null) */
  IsInterestBefore?: boolean;

  /** lãi (null) */
  Rate?: number;

  /** k/1 triệu (null) */
  TypeInterest?: number;

  /** thời gian vay */
  TimeBorrow?: number;

  /** kỳ phí */
  PeriodFee?: number;

  /** Mã cửa hàng */
  ShopId?: number;

  /** Mã khách hàng mới thì truyền 0 */
  CustomerId?: number;
}

export interface AppCapitalExtraAdd {
  /** Mã HĐ. 0: tạo mới, >0: cập nhập */
  ID?: number;

  /** Người tạo */
  CapitalID?: number;

  /** Số tiền */
  TotalMoney?: number;

  /** Ngày thêm/bớt vốn */
  DateExtra?: Date;

  /** Ghi chú */
  Note?: string;

  /** Loại 1: Thêm vốn, 2 Bớt vốn */
  Type?: number;

  /** Trạng thái 1: Tạo mới, 0 Xóa */
  Status?: number;

  /** ID Ví */
  WalletId?: number;
}

export interface AppCapitalExtraHistory {
  /**  */
  Fullname?: string;

  /**  */
  ShopName?: string;

  /**  */
  CapitalID?: number;

  /**  */
  TotalMoney?: number;

  /**  */
  DateExtra?: Date;

  /**  */
  CreateDate?: Date;

  /**  */
  Note?: string;

  /**  */
  Status?: number;

  /**  */
  WalletName?: string;
}

export interface AppCapitalExtraHistoryListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppCapitalExtraHistory[];
}

export interface AppCapitalHistoryView {
  /** Nội dung */
  Note?: string;

  /** Số tiền */
  Money?: number;

  /** Thời gian tạo */
  CreatedAt?: Date;

  /** Username người tạo */
  CreatedUsername?: string;

  /** Họ tên người tạo */
  CreatedFullName?: string;

  /** Tên ví */
  WalletName?: string;
}

export interface AppCapitalHistoryViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppCapitalHistoryView[];
}

export interface AppCashBookData {
  /** Người Nộp/Nhận */
  CustomerName?: string;

  /** Lý do */
  StrType?: string;

  /** Ngày */
  TransactionDate?: Date;

  /** Nhân viên */
  Username?: string;

  /** Số Tiền */
  TotalMoney?: number;

  /** ghi chú */
  Note?: string;

  /** Mã phiếu */
  CodeText?: string;

  /** Trạng thái */
  StatusName?: string;

  /** ID Loại phiếu */
  TypeID?: number;

  /** ID ngân hàng */
  PaymentID?: number;

  /**  */
  Status?: number;

  /**  */
  PaymentType?: number;

  /** Ví */
  BankCode?: string;
}

export interface AppCashBookDataListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppCashBookData[];
}

export interface AppChangeInfomation {
  /** Họ và tên */
  FullName?: string;

  /** Số điện thoại */
  Phone?: string;

  /** Email */
  Email?: string;
}

export interface AppChangePassword {
  /** Mật khẩu cũ */
  OldPassword?: string;

  /** Mật khẩu mới */
  NewPassword?: string;
}

export interface AppChangeStatusContract {
  /** Mã HĐ */
  ContractId?: number;

  /** Trạng thái */
  Status?: number;

  /** Ghi chú */
  Note?: string;
}

export interface AppChangeTelesales {
  /** Mã Khách hàng */
  Id?: number;

  /** Id Telesales */
  TelesalesId?: number;
}

export interface AppChangeTimer {
  /** Mã Khách hàng */
  Id?: number;

  /** Thời gian hẹn */
  Timer?: Date;
}

export interface AppConfigCollectExpense {
  /** ID bản ghi */
  ConfigCollectionExpenseID?: number;

  /** Tên */
  ConfigName?: string;

  /** Loại */
  TypeID?: number;

  /** Trạng thái */
  Status?: number;

  /** Thời gian tạo */
  CreateDate?: Date;

  /** Người tạo */
  CreateUserName?: string;

  /** Loại bằng text */
  StrType?: string;
}

export interface AppConfigCollectExpenseListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppConfigCollectExpense[];
}

export interface AppConfigMarketingStatus {
  /** ID bản ghi = 0 là tạo */
  ConfigMaketingStatusID?: number;

  /** Tên */
  Name?: string;

  /** trạng thái */
  Status?: number;
}

export interface AppConfigServiceAdd {
  /** ID dịch vụ : 0 là tạo , > 0 là sửa */
  Id?: number;

  /** Mã cửa hàng */
  ShopId?: number;

  /** Tên dịch vụ */
  Name?: string;

  /** Mã dịch vụ */
  Code?: string;

  /** trạng thái: 1 hoạt động, 0 đã khóa */
  Status?: number;

  /** ngày tạo */
  CreatedAt?: Date;

  /** Id người tạo */
  CreatedBy?: number;

  /** ngày sửa */
  ModifyAt?: Date;

  /** Id ngày sửa */
  ModifyBy?: number;

  /** Loại dịch vụ 1: Spa, 2: Thuê xe */
  Type?: number;
}

export interface AppConfigServiceByShopView {
  /** ID dịch vụ */
  Id?: number;

  /** Tên dịch vụ */
  Name?: string;

  /** Mã dịch vụ */
  Code?: string;
}

export interface AppConfigServiceByShopViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppConfigServiceByShopView[];
}

export interface AppConfigServiceView {
  /** ID dịch vụ */
  Id?: number;

  /** ID cửa hàng */
  ShopId?: number;

  /** Tên dịch vụ */
  Name?: string;

  /** Mã dịch vụ */
  Code?: string;

  /** trạng thái: 1 hoạt động, 0 đã khóa */
  Status?: number;

  /** Ngày tạo */
  CreatedAt?: Date;

  /** Loại dịch vụ 1: Spa, 2: Thuê xe */
  Type?: number;
}

export interface AppConfigServiceViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppConfigServiceView[];
}

export interface AppConfigUserWaller {
  /**  */
  Id?: number;

  /**  */
  WalletId?: number;

  /**  */
  WalletName?: string;

  /**  */
  UserId?: number;

  /**  */
  UserName?: string;

  /**  */
  DepartmentId?: number;

  /**  */
  DepartmentName?: string;

  /**  */
  Money?: number;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;
}

export interface AppConfigUserWallerListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppConfigUserWaller[];
}

export interface AppConfigUserWalletAdd {
  /** Mã Id 0 là thêm mới, > 0 cập nhập */
  Id?: number;

  /** Mã ví */
  WalletId?: number;

  /** Mã nhân viên */
  UserId?: number;
}

export interface AppContractAdd {
  /** Mã HĐ. 0: tạo mới, > 0: cập nhập */
  Id?: number;

  /** Id khách hàng không có truyền 0 */
  DataCustomerId?: number;

  /** Tên khách hàng */
  FullName?: string;

  /** Giới tính: 0: Nam, 1: Nữ */
  Gender?: number;

  /** SĐT khách hàng */
  Phone?: string;

  /** Độ tuổi */
  Age?: string;

  /** Ngày làm HĐ */
  WorkdayAt?: Date;

  /** Kỳ điều trị tiếp theo */
  NextTreatment?: number;

  /** List Dịch vụ */
  ListService?: AppDataCustomerServiceAdd[];

  /** List sản phẩm */
  ListProduct?: AppContractProductAdd[];

  /** mã telesales */
  TelesalesId?: number;

  /** mã Sale */
  SaleId?: number;

  /** nguồn khách */
  SourceCustomer?: number;

  /** nguồn dịch vụ */
  SourceService?: number;

  /** ghi chú */
  Note?: string;

  /** Tổng số buổi */
  TotalDay?: number;

  /** Tổng số tiền */
  TotalMoney?: number;

  /** Địa chỉ */
  Address?: string;

  /** Link facebook */
  LinkFacebook?: string;

  /** Hình thức thanh toán */
  PaymentContractId?: number;

  /** Ngày vay, k chọn mặc định là hm nay */
  FromDate?: Date;

  /** Kì đóng tiền */
  Frequency?: number;

  /** Thời gian vay */
  LoanTime?: number;

  /** Lãi suất */
  Rate?: number;

  /** Bác sĩ */
  DoctorId?: number;

  /** Kỹ thuật viên */
  TechniciansId?: number;

  /** đóng lãi trước = 1 */
  IsBefore?: number;

  /** CCCD/CMND */
  NumberCard?: string;

  /** Mã campain */
  CampainId?: number;
}

export interface AppContractCourseAdd {
  /** Mã HĐ */
  ContractId?: number;

  /** Ngày làm */
  FromDate?: Date;

  /** Ngày làm tiếp theo */
  ToDate?: Date;

  /** mã dịch vụ */
  ServiceId?: number;

  /** Mã BS */
  DoctorId?: number;

  /**  */
  TechniciansId?: number;

  /** Hoa hồng BS */
  DoctorRose?: number;

  /** Hoa hồng KTV */
  TechniciansRose?: number;
}

export interface AppContractHistoryView {
  /**  */
  Note?: string;

  /**  */
  Money?: number;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedUserName?: string;

  /**  */
  WalletName?: string;
}

export interface AppContractHistoryViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppContractHistoryView[];
}

export interface AppContractId {
  /** mã hợp đồng */
  Id?: number;
}

export interface AppContractProductAdd {
  /** thêm mới là 0, cập nhập > 0 */
  Id?: number;

  /** Id sản phẩm */
  ProductId?: number;

  /** Ngày mua */
  BuyDate?: Date;

  /** Số lượng */
  Amount?: number;

  /** Giá tiền */
  Money?: number;

  /** Nhân viên bán */
  UserSell?: number;

  /** Số buổi */
  TotalDay?: number;
}

export interface AppContractTimerAdd {
  /** Mã hợp đồng */
  ContractId?: number;

  /** 1: hẹn giờ, -99 hủy hẹn giờ */
  Status?: number;

  /** Thời gian hẹn */
  DateTimer?: Date;

  /** Ghi chú */
  Note?: string;

  /** Nhân viên */
  StaffName?: string;
}

export interface AppContractTimerEdit {
  /** Mã Id */
  Id?: number;

  /** Mã hợp đồng */
  ContractId?: number;

  /** Thời gian hẹn */
  DateTimer?: Date;

  /** Ghi chú */
  Note?: string;

  /** Nhân viên */
  StaffName?: string;
}

export interface AppContractTreatmentAdd {
  /** Id */
  Id?: number;

  /** Id hợp đồng */
  ContractId?: number;

  /** Ngày mua */
  DateSell?: Date;

  /** Id sản phẩm */
  ProductId?: number;

  /** Số lượng */
  Amount?: number;

  /** Mã nhân viên bán */
  UserSellId?: number;

  /** Số buổi điều trị */
  DayTreatment?: number;

  /** Thời gian bảo hành */
  TimeGuarantee?: string;

  /**  */
  NumberRemaining?: number;
}

export interface AppCreateCollectExpense {
  /** Tiền */
  TotalMoney?: number;

  /** PaymentType lấy từ meta - danh sách phiếu */
  PaymentType?: number;

  /** Ghi chú */
  Note?: string;

  /** Tên KH */
  CustomerName?: string;

  /** Ngày giao dịch định dạng dd/MM/yyyy HH:mm */
  TransactionTime?: string;

  /** Ví */
  BankCardID?: number;
}

export interface AppCreateCollectExpenseExpect {
  /** ID bản ghi */
  CollectExpenseExpectedId?: number;

  /** Tên KH */
  CustomerName?: string;

  /** Tiền */
  TotalMoney?: number;

  /** Lý do chi */
  Reason?: string;
}

export interface AppCreateCollectExpenseExpectMarketing {
  /** ID bản ghi */
  CollectExpenseExpectedId?: number;

  /** Tên KH */
  CustomerName?: string;

  /** Tiền */
  TotalMoney?: number;

  /** Lý do chi */
  Reason?: string;

  /** Ví */
  WalletID?: number;

  /** Người duyệt */
  UserAcceptID?: number;

  /**  */
  AdsID?: number;
}

export interface AppCreateConfig {
  /** ID bản ghi */
  ConfigCollectionExpenseID?: number;

  /** Tên */
  ConfigName?: string;

  /** Loại */
  TypeID?: number;

  /** Trạng thái */
  Status?: number;
}

export interface AppCreateFileContractAdd {
  /** mã hợp đồng */
  ContractId?: number;

  /** đường dẫn file */
  FilePath?: string;
}

export interface AppCreateOwnerAdd {
  /** ID : 0 là tạo mới > 0 là cập nhập */
  UserId?: number;

  /** Tài khoản */
  UserName?: string;

  /** Mật khẩu */
  Password?: string;

  /** Tên */
  FullName?: string;

  /** SĐT */
  Phone?: string;

  /** Trạng thái */
  Status?: number;

  /** Tên cửa hàng */
  ShopName?: string;
}

export interface AppCreateStaffAdd {
  /** ID user : 0 là tạo mới >0 là edit */
  UserID?: number;

  /** Tên tài khoản */
  UserName?: string;

  /** Mật khẩu */
  Password?: string;

  /** Tên */
  FullName?: string;

  /** SĐt */
  Phone?: string;

  /** Email */
  Email?: string;

  /** Trạng thái 1 : hoạt động , 0 là khóa */
  Status?: number;

  /** Id phòng ban */
  DepartmentId?: number;

  /** Id Vị trí */
  PositionId?: number;

  /** Danh sách Id của nhân viên quản lý: Không quản lý ai thì truyền null, Tất cả truyền -100 */
  ListUserIdManage?: string;
}

export interface AppCustomerFileExportRequestAdd {
  /** id của hợp đồng */
  ContractId?: number;

  /** id của khách hàng */
  CustomerId?: number;

  /** id của kho
1: Kho 1
2: Kho 2
3: Kho 3
4: Kho 4
5: Kho 5 */
  WareHouseId?: number;

  /** ghi chú (nếu có) */
  Note?: string;
}

export interface AppCustomerPayment {
  /** ID bảng lịch */
  PaymentID?: number;

  /** ID HĐ */
  ContractID?: number;

  /** ID vis */
  WalletID?: number;

  /** Tiền Khách trả */
  CustomerPay?: number;
}

export interface AppDataCustomerAdd {
  /** Mã Khách hàng. 0: tạo mới, >0: cập nhập */
  Id?: number;

  /** Tên KH */
  FullName?: string;

  /** Độ tuổi */
  Age?: string;

  /** Giới tính: 0 nam, 1: nữ */
  Gender?: number;

  /** SĐT */
  Phone?: string;

  /** Mã chiến dịch */
  CampainId?: number;

  /**  */
  ListService?: AppDataCustomerServiceAdd[];

  /**  */
  ListProduct?: AppDataCustomerProductAdd[];

  /** CMND/CCCD */
  NumberCard?: string;

  /** Id nhân viên chatpage */
  ChatPageId?: number;

  /** link facebook */
  LinkFacebook?: string;

  /**  */
  Note?: string;
}

export interface AppDataCustomerProductAdd {
  /** Id sản phẩm */
  ProductId?: number;

  /** Ngày mua */
  BuyDate?: Date;

  /** Số lượng */
  Amount?: number;

  /** Giá tiền */
  Money?: number;

  /**  */
  TotalDay?: number;
}

export interface AppDataCustomerServiceAdd {
  /** Id dịch vụ */
  ServiceId?: number;

  /** Số ngày */
  Day?: number;

  /** Số tiền */
  Money?: number;

  /**  */
  StartDate?: Date;

  /**  */
  PriceEachItem?: number;

  /**  */
  TotalProduct?: number;
}

export interface AppGeneralCommentAdd {
  /** Bình luận */
  Note?: string;

  /** Loại: 1 media, 2 campain */
  TypeId?: number;

  /** mã media không có truyền null */
  MediaId?: number;

  /** mã campain không có truyền null */
  CampainId?: number;
}

export interface AppGetComment {
  /**  */
  DataCustomerId?: number;

  /**  */
  Note?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;
}

export interface AppGetCommentListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppGetComment[];
}

export interface AppGetCommentMedia {
  /** Mã media */
  MediaId?: number;

  /** Comment */
  Note?: string;

  /** Ngày tạo */
  CreatedAt?: Date;
}

export interface AppGetCommentMediaListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppGetCommentMedia[];
}

export interface AppGetListCampainAction {
  /**  */
  Id?: number;

  /**  */
  CampainId?: number;

  /**  */
  TotalMessage?: number;

  /**  */
  AmountSpent?: number;

  /**  */
  CreatedAt?: Date;

  /**  */
  Note?: string;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;
}

export interface AppGetListCampainActionListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppGetListCampainAction[];
}

export interface AppGetLog {
  /** Mã Id */
  Id?: number;

  /** Số tiền */
  Money?: number;

  /** Ghi chứ */
  Note?: string;

  /** Thời gian tạo */
  CreatedAt?: Date;
}

export interface AppGetLogListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppGetLog[];
}

export interface AppGetLogTransaction {
  /** Mã Id */
  Id?: number;

  /** Note */
  Note?: string;

  /** Ngày tạo */
  CreatedAt?: Date;
}

export interface AppGetLogTransactionListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppGetLogTransaction[];
}

export interface AppHistoryPaymentSpa {
  /** ID bản ghi */
  PaymentScheduleID?: number;

  /** Ngày làm DV */
  PayDate?: Date;

  /** Người thao tác */
  UserName?: string;

  /** Bác sĩ */
  DoctorName?: string;

  /** Số tiền bác sĩ */
  DoctorMoney?: number;

  /** Kĩ thuật viên */
  EnginerName?: string;

  /** Tiền kĩ thuật viên */
  EninerMoney?: number;

  /** Ví */
  BankCardName?: string;

  /** Tiền giao dịch */
  TotalMoney?: number;

  /** Ghi chú */
  Note?: string;
}

export interface AppHistoryPaymentSpaListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppHistoryPaymentSpa[];
}

export interface AppImportProductAdd {
  /** Id 0: thêm mới, > 0 cập nhập */
  Id?: number;

  /** Mã sản phẩm */
  ProductId?: number;

  /** Số lượng nhập */
  Amount?: number;

  /** Số tiền */
  Money?: number;

  /** Nhân viên */
  StaffId?: number;
}

export interface AppListPaymentCampain {
  /**  */
  Id?: number;

  /**  */
  DataCustomerId?: number;

  /**  */
  CampainId?: number;

  /**  */
  MediaId?: number;

  /**  */
  ActionId?: number;

  /**  */
  ActionName?: string;

  /**  */
  TypeId?: number;

  /**  */
  TypeName?: string;

  /**  */
  Money?: number;

  /**  */
  PathImage?: string;

  /**  */
  Note?: string;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  FullName?: string;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  VerifyId?: number;

  /**  */
  VerifyName?: string;

  /**  */
  MediaCode?: string;

  /**  */
  StaffId?: number;

  /**  */
  StaffName?: string;
}

export interface AppListPaymentCampainListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppListPaymentCampain[];
}

export interface AppMarketingActionComment {
  /** mã đơn marketing */
  MarketingID?: number;

  /** Mã Action:
1: Tổng quát
2: Tiềm năng
3: Đã hẹn
4: Bùng lịch
5: Rời lịch
6: Fail
7: Done */
  NewActionId?: number;

  /** comment */
  Comment?: string;
}

export interface AppMarketingAdd {
  /**  */
  ID?: number;

  /**  */
  Fullname?: string;

  /**  */
  Phone?: string;

  /**  */
  Note?: string;

  /**  */
  StaffID?: number;

  /**  */
  AdsID?: number;

  /**  */
  Age?: number;

  /**  */
  Gender?: number;

  /**  */
  Facebook?: string;

  /**  */
  SourceService?: number;

  /**  */
  Media?: string;

  /**  */
  Page?: string;

  /**  */
  CampainId?: number;
}

export interface AppMediaAdd {
  /** Mã media. 0: tạo mới, >0: cập nhập */
  Id?: number;

  /** Tên media. */
  Name?: string;

  /** Tài khoản */
  Code?: string;

  /** Id dịch vụ. */
  ServiceId?: number;

  /** Nhân viên media. */
  MediaUserId?: number;

  /** Url */
  Url?: string;

  /** Nhân viên Biên kịch */
  WriterUserId?: number;
}

export interface AppPaymentCampainAdd {
  /** Mã Id. 0: tạo mới, >0: cập nhập */
  Id?: number;

  /** Mã campainId. Không có truyền null */
  CampainId?: number;

  /** Mã khách hàng. Không có truyền null */
  DataCustomerId?: number;

  /** Mã Media. Không có truyền null */
  MediaId?: number;

  /** Loại chi */
  ActionId?: number;

  /** Loại phiếu */
  TypeId?: number;

  /** Số tiền */
  Money?: number;

  /** Link ảnh */
  PathImage?: string;

  /** Ghi chú */
  Note?: string;

  /** Campain: 1,  khách hàng: 2, Media: 3, hợp đồng: 5, sản phẩm 6 */
  SourceId?: number;

  /** Danh sách sản phẩm không có thì truyền rỗng */
  ListProduct?: PaymentProductAdd[];

  /** Mã nhân viên không có thì null */
  StaffId?: number;

  /** Mã HĐ không có truyền null */
  ContractId?: number;
}

export interface AppProductAdd {
  /** Id 0: thêm mới, > 0 cập nhập */
  Id?: number;

  /** Tên sản phẩm */
  Name?: string;

  /** Mã sản phẩm */
  Code?: string;

  /** Số lượng */
  Amount?: number;
}

export interface AppReportCampainDataCustomer {
  /**  */
  MediaId?: number;

  /**  */
  CampainId?: number;

  /**  */
  ServiceId?: number;

  /**  */
  ServiceName?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  SpendTarget?: number;

  /**  */
  Message?: number;

  /**  */
  MessageMoney?: number;

  /**  */
  CountData?: number;

  /**  */
  DataMoney?: number;

  /**  */
  CountDataDone?: number;

  /**  */
  DataDoneMoney?: number;

  /**  */
  AllottedExpenditure?: number;

  /**  */
  TotalCampainMoney?: number;

  /**  */
  Revenue?: number;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  NameMedia?: string;

  /**  */
  CreatedAtMedia?: Date;

  /**  */
  MediaUserId?: number;

  /**  */
  MediaUserName?: string;

  /**  */
  IsEnable?: boolean;

  /**  */
  MediaCode?: string;
}

export interface AppReportCampainDataCustomerListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppReportCampainDataCustomer[];
}

export interface AppReportMediaDataCustomer {
  /**  */
  MediaId?: number;

  /**  */
  CampainId?: number;

  /**  */
  ServiceId?: number;

  /**  */
  ServiceName?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  SpendTarget?: number;

  /**  */
  Message?: number;

  /**  */
  MessageMoney?: number;

  /**  */
  CountData?: number;

  /**  */
  DataMoney?: number;

  /**  */
  CountDataDone?: number;

  /**  */
  DataDoneMoney?: number;

  /**  */
  AllottedExpenditure?: number;

  /**  */
  TotalCampainMoney?: number;

  /**  */
  Revenue?: number;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  MediaUserId?: number;

  /**  */
  MediaUserName?: string;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  Name?: string;

  /**  */
  Code?: string;

  /**  */
  IsEnable?: boolean;

  /**  */
  Url?: string;

  /**  */
  WriterUserId?: number;

  /**  */
  WriterUserName?: string;
}

export interface AppReportMediaDataCustomerListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppReportMediaDataCustomer[];
}

export interface AppShopAdd {
  /** ID cửa hàng : 0 là tạo , >0 là sửa */
  ShopID?: number;

  /** Tên chi nhánh */
  ShopName?: string;

  /** Địa chỉ */
  Address?: string;

  /** SĐT */
  Phone?: string;

  /** Người đại diện */
  Represent?: string;

  /** Trạng thái : 1 Hoạt động , 0 Tạm ngưng */
  Status?: number;

  /** Vốn đầu tư */
  InvestMoney?: number;

  /** Thông tin doanh nghiệp */
  InfomationCompany?: string;

  /** Mã thành phố */
  CityID?: number;

  /** Mã Quận/Huyện */
  DistrictID?: number;

  /** Mã số doanh nghiệp */
  CodeCompany?: string;

  /** Năm sinh */
  Birtday?: string;

  /** CCCD/CMND */
  NationalID?: string;
}

export interface AppShopView {
  /** ShopID */
  ShopID?: number;

  /** Tên cửa hàng */
  ShopName?: string;

  /** Địa chỉ */
  Address?: string;

  /** SĐT */
  Phone?: string;

  /** thời gian tạo */
  CreateDate?: Date;

  /** Trạng thái : 1 hoạt động, 0 tạm ngưng */
  Status?: number;

  /** Quỹ tiền mặt */
  CashMoney?: number;

  /** Vốn đầu tư */
  InvestMoney?: number;

  /** Thông tin doanh nghiệp */
  InfomationCompany?: string;

  /** Mã thành phố */
  CityID?: number;

  /** Mã Quận/Huyện */
  DistrictID?: number;

  /** Mã số doanh nghiệp */
  CodeCompany?: string;

  /** Năm sinh */
  Birtday?: string;

  /** CCCD/CMND */
  NationalID?: string;

  /** Người đại diện */
  Represent?: string;
}

export interface AppShopViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppShopView[];
}

export interface AppSourceCustomerAdd {
  /** Mã nguồn khách. 0: tạo mới, >0: cập nhập */
  Id?: number;

  /** Tên nguồn khách */
  Name?: string;
}

export interface AppStaffMeta {
  /**  */
  UserID?: number;

  /**  */
  UserName?: string;

  /**  */
  FullName?: string;

  /**  */
  DepartmentId?: number;

  /**  */
  PositionId?: number;

  /**  */
  DepartmentName?: string;

  /**  */
  PositionName?: string;
}

export interface AppStaffMetaListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppStaffMeta[];
}

export interface AppStaffViewMeta {
  /**  */
  UserID?: number;

  /**  */
  UserName?: string;

  /**  */
  FullName?: string;
}

export interface AppStaffViewMetaListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppStaffViewMeta[];
}

export interface AppStatusGeneral {
  /**  */
  Id?: number;

  /**  */
  Name?: string;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;
}

export interface AppStatusGeneralAdd {
  /** Mã Id 0 tạo mới, > 0 cập nhập */
  Id?: number;

  /** Tên trạng thái */
  Name?: string;

  /** Loại: 1: Telesales, 2: Media, 3: Contract */
  TypeId?: number;

  /** Trạng thái */
  Status?: number;
}

export interface AppStatusGeneralListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppStatusGeneral[];
}

export interface AppSummaryCashBook {
  /** ID ngân hàng : 0 là tiền mặt */
  BankCardID?: number;

  /** Thu */
  MoneyCollect?: number;

  /** Chi */
  MoneyExpense?: number;

  /** Đầu kì */
  BeforeMoney?: number;

  /** Cuối kì */
  AfterMoney?: number;
}

export interface AppSummaryCashBookResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppSummaryCashBook;
}

export interface AppTransactionAdd {
  /** Tên KH */
  CustomerName?: string;

  /** Số tiền */
  Money?: number;

  /** Ghi chú */
  Note?: string;

  /** Loại phiếu */
  TypeId?: number;

  /** Mã nhân viên nếu có. Không có truyền 0 */
  StaffId?: number;

  /** Chi phân bổ 1, Chi mục tiêu 2 Không phải phiếu tri truyền 0 */
  ActionPaymentId?: number;
}

export interface AppUpdateStatusOrCommentMedia {
  /** Mã media */
  Id?: number;

  /** Trạng thái */
  Status?: number;

  /** Comment */
  Note?: string;
}

export interface AppUpdateStatusOrCommentModel {
  /** Mã Khách hàng */
  Id?: number;

  /** Trạng thái */
  Status?: number;

  /** Comment */
  Note?: string;

  /** thoi gian hen */
  AlarmDate?: Date;
}

export interface AppUserShopOwnerView {
  /**  */
  UserName?: string;

  /**  */
  FullName?: string;

  /**  */
  Phone?: string;

  /**  */
  Email?: string;

  /**  */
  StatusName?: string;

  /**  */
  UserID?: number;

  /**  */
  Status?: number;

  /**  */
  GroupName?: string;

  /**  */
  CreateDate?: Date;

  /**  */
  ExpiredDate?: Date;

  /**  */
  LastLogin?: Date;

  /**  */
  Note?: string;
}

export interface AppUserShopOwnerViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppUserShopOwnerView[];
}

export interface AppUserStaffView {
  /**  */
  UserName?: string;

  /**  */
  FullName?: string;

  /**  */
  Phone?: string;

  /**  */
  Email?: string;

  /**  */
  Address?: string;

  /**  */
  ShopName?: string;

  /**  */
  ShopID?: number;

  /**  */
  StatusName?: string;

  /**  */
  UserID?: number;

  /**  */
  Status?: number;

  /**  */
  CreateDate?: Date;

  /**  */
  DepartmentId?: number;

  /**  */
  PositionId?: number;

  /**  */
  DepartmentName?: string;

  /**  */
  PositionName?: string;

  /**  */
  ListUserIdManage?: string;
}

export interface AppUserStaffViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppUserStaffView[];
}

export interface AppUserView {
  /** Tài khoản */
  UserName?: string;

  /** Tên */
  FullName?: string;

  /** SĐt */
  Phone?: string;

  /** Email */
  Email?: string;

  /** Địa chỉ */
  Address?: string;

  /** Cửa hàng */
  ShopName?: string;

  /** ID cửa hàng */
  ShopID?: number;

  /** Trạng thái */
  StatusName?: string;

  /** UserID */
  UserID?: number;

  /** Trạng thái 1: Làm việc, 0 : Nghỉ , -11:Hủy */
  Status?: number;

  /** Thời gian tạo */
  CreateDate?: Date;

  /** Id phòng ban */
  DepartmentId?: number;

  /** Id vị trí */
  PositionId?: number;

  /** Tên phòng ban */
  DepartmentName?: string;

  /** Tên vị trí */
  PositionName?: string;
}

export interface AppUserViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppUserView[];
}

export interface AppUserWalletAdd {
  /** Mã nhân viên */
  UserId?: number;

  /** Danh sách ví */
  ListWallet?: number[];
}

export interface AppWalletAdd {
  /** ID ví : 0 là tạo , > 0 là sửa */
  Id?: number;

  /** tên ví */
  Name?: string;
}

export interface AppWalletTransferAdd {
  /** Mã cửa hàng */
  ShopId?: number;

  /** Mã ví (Từ ví) */
  FromWalletId?: number;

  /** Mã ví (Đến ví) */
  ToWalletId?: number;

  /** Số tiền */
  Money?: number;

  /** Ghi chú */
  Note?: string;
}

export interface AppWalletTransferChangeStatus {
  /** Mã Id */
  Id?: number;

  /** Trạng thái 0: Hủy, 2: Xác nhận */
  Status?: number;
}

export interface AppWalletView {
  /** ID ví */
  Id?: number;

  /** tên ví */
  Name?: string;

  /** số tiền */
  Money?: number;

  /** trạng thái: 1 hoạt động, 0 đã khóa */
  Status?: number;

  /** ngày tạo */
  CreatedAt?: Date;
}

export interface AppWalletViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: AppWalletView[];
}

export interface AppWithdrawMoney {
  /**  */
  Money?: number;

  /**  */
  Note?: string;
}

export interface CapitalView {
  /**  */
  Id?: number;

  /**  */
  WalletId?: number;

  /**  */
  WalletName?: string;

  /**  */
  FullName?: string;

  /**  */
  Phone?: string;

  /**  */
  Money?: number;

  /**  */
  DonatedAt?: Date;

  /**  */
  Note?: string;

  /**  */
  StatusName?: string;

  /**  */
  Status?: number;

  /**  */
  TypeRate?: number;

  /**  */
  TypeRateName?: string;

  /**  */
  TypeCapital?: number;

  /**  */
  TypeCapitalName?: string;

  /**  */
  IsInterestBefore?: boolean;

  /**  */
  Rate?: number;

  /**  */
  TypeInterest?: number;

  /**  */
  TimeBorrow?: number;

  /**  */
  PeriodFee?: number;

  /**  */
  CustomerId?: number;

  /**  */
  ShopId?: number;

  /**  */
  NextDate?: Date;

  /**  */
  TotalInterestPaid?: number;

  /**  */
  TotalMoneyCurrent?: number;
}

export interface CapitalViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: CapitalView[];
}

export interface ChartMarketing {
  /**  */
  name?: string;

  /**  */
  value?: number;
}

export interface ContractCourseView {
  /**  */
  Id?: number;

  /**  */
  ContractId?: number;

  /**  */
  FromDate?: Date;

  /**  */
  ToDate?: Date;

  /**  */
  ServiceId?: number;

  /**  */
  ServiceName?: string;

  /**  */
  DoctorId?: number;

  /**  */
  DoctorName?: string;

  /**  */
  TechniciansId?: number;

  /**  */
  TechniciansName?: string;

  /**  */
  DoctorRose?: number;

  /**  */
  TechniciansRose?: number;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;
}

export interface ContractCourseViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ContractCourseView[];
}

export interface ContractProductModel {
  /**  */
  Id?: number;

  /**  */
  ContractId?: number;

  /**  */
  ProductId?: number;

  /**  */
  ProductName?: string;

  /**  */
  BuyDate?: Date;

  /**  */
  Amount?: number;

  /**  */
  Money?: number;

  /**  */
  UserSell?: number;

  /**  */
  UserSellName?: string;

  /**  */
  TotalDay?: number;
}

export interface ContractTimerView {
  /**  */
  Id?: number;

  /**  */
  ContractId?: number;

  /**  */
  DateTimer?: Date;

  /**  */
  StaffName?: string;

  /**  */
  Note?: string;

  /**  */
  Status?: number;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;
}

export interface ContractTimerViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ContractTimerView[];
}

export interface ContractTreatmentReport {
  /**  */
  Id?: number;

  /**  */
  ContractId?: number;

  /**  */
  CampainId?: number;

  /**  */
  CreatedContract?: Date;

  /**  */
  UserSellId?: number;

  /**  */
  UserSellName?: string;

  /**  */
  CustomerId?: number;

  /**  */
  FullName?: string;

  /**  */
  ProductId?: number;

  /**  */
  ProductName?: string;

  /**  */
  Amount?: number;

  /**  */
  NumberRemaining?: number;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  DateSell?: Date;
}

export interface ContractTreatmentReportListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ContractTreatmentReport[];
}

export interface ContractTreatmentView {
  /**  */
  Id?: number;

  /**  */
  ContractId?: number;

  /**  */
  DateSell?: Date;

  /**  */
  ProductId?: number;

  /**  */
  ProductName?: string;

  /**  */
  Amount?: number;

  /**  */
  UserSellId?: number;

  /**  */
  UserSellName?: string;

  /**  */
  DayTreatment?: number;

  /**  */
  TimeGuarantee?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;
}

export interface ContractTreatmentViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ContractTreatmentView[];
}

export interface ContractView {
  /**  */
  Id?: number;

  /**  */
  CampianId?: number;

  /**  */
  MediaId?: number;

  /**  */
  DataCustomerId?: number;

  /**  */
  TotalDay?: number;

  /**  */
  FullName?: string;

  /**  */
  Phone?: string;

  /**  */
  WorkdayAt?: Date;

  /**  */
  TotalMoney?: number;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  Age?: string;

  /**  */
  Address?: string;

  /**  */
  LinkFacebook?: string;

  /**  */
  TelesalesId?: number;

  /**  */
  SaleId?: number;

  /**  */
  SourceCustomer?: number;

  /**  */
  SourceService?: number;

  /**  */
  SourceServiceName?: string;

  /**  */
  Note?: string;

  /**  */
  TelesaleName?: string;

  /**  */
  SaleName?: string;

  /**  */
  PaymentMoney?: number;

  /**  */
  TotalMoneyCurrent?: number;

  /**  */
  DebitMoney?: number;

  /**  */
  CountPayment?: number;

  /**  */
  NextDate?: Date;

  /**  */
  DebtMoney?: number;

  /**  */
  Gender?: number;

  /**  */
  ListService?: DataCustomerServiceModel[];

  /**  */
  ListProduct?: ContractProductModel[];

  /**  */
  PaymentContractId?: number;

  /**  */
  PaymentContractName?: string;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  MediaUserId?: number;

  /**  */
  MediaUserName?: string;

  /**  */
  CreatedCampainAt?: Date;

  /**  */
  CreatedMediaAt?: Date;

  /**  */
  NextTreatment?: number;

  /**  */
  FromDate?: Date;

  /**  */
  Frequency?: number;

  /**  */
  LoanTime?: number;

  /**  */
  Rate?: number;

  /**  */
  DoctorId?: number;

  /**  */
  DoctorName?: string;

  /**  */
  TechniciansId?: number;

  /**  */
  TechniciansName?: string;

  /**  */
  TotalInterstEarned?: number;

  /**  */
  NumberCard?: string;

  /**  */
  MediaCode?: string;

  /**  */
  CreateDataTele?: Date;

  /**  */
  ChatPageId?: number;

  /**  */
  ChatPageName?: string;

  /**  */
  TotalExpense?: number;
}

export interface ContractViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ContractView[];
}

export interface CustomerCreate {
  /** ID KH 0 là tạo mới, > 0 cập nhập */
  CustomerID?: number;

  /** Tên KH */
  CustomerName?: string;

  /** SĐT */
  Phone?: string;

  /** CCCD/CMND */
  NationalId?: string;

  /** Địa chỉ */
  Address?: string;

  /** Trạng thái */
  Status?: number;

  /** ID cửa hàng */
  ShopID?: number;

  /** Độ tuổi */
  Age?: number;

  /** Link Facebook */
  Facebook?: string;

  /** Giới tính: 0: Nam, 1: Nữ */
  Gender?: number;
}

export interface CustomerFileExportRequestView {
  /**  */
  ID?: number;

  /**  */
  ContractId?: number;

  /**  */
  CustomerName?: string;

  /**  */
  WareHouseId?: number;

  /**  */
  WareHouseName?: string;

  /**  */
  Note?: string;

  /**  */
  CreatedByFullName?: string;

  /**  */
  CreatedDate?: Date;

  /**  */
  UpdateDate?: Date;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  HandleFullName?: string;
}

export interface CustomerFileExportRequestViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: CustomerFileExportRequestView[];
}

export interface CustomerPhoneBook {
  /** Tên trong danh bạ */
  FullName?: string;

  /** SĐT */
  Phone?: string;
}

export interface CustomerPhoneBookAdd {
  /** ID KH */
  CustomerId?: number;

  /** Danh sách danh bạ */
  ListPhoneBook?: CustomerPhoneBook[];
}

export interface CustomerView {
  /** ID KH */
  CustomerID?: number;

  /** Tên KH */
  CustomerName?: string;

  /** SĐT */
  Phone?: string;

  /** CCCD/CMND */
  NationalId?: string;

  /** Địa chỉ */
  Address?: string;

  /** Trạng thái */
  Status?: number;

  /** Thời gian tạo */
  CreateDate?: Date;

  /** ID cửa hàng */
  ShopID?: number;

  /** Tên cửa hàng */
  ShopName?: string;

  /** Giới tính: 0: Nam, 1: Nữ */
  Gender?: number;
}

export interface CustomerViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: CustomerView[];
}

export interface DataCustomerProductModel {
  /**  */
  Id?: number;

  /**  */
  DataCustomerId?: number;

  /**  */
  ProductId?: number;

  /**  */
  ProductName?: string;

  /**  */
  BuyDate?: Date;

  /**  */
  Amount?: number;

  /**  */
  Money?: number;

  /**  */
  TotalDay?: number;
}

export interface DataCustomerServiceModel {
  /**  */
  Id?: number;

  /**  */
  ServiceId?: number;

  /**  */
  Name?: string;

  /**  */
  Day?: number;

  /**  */
  Money?: number;

  /**  */
  DataCustomerId?: number;

  /**  */
  StartDate?: Date;

  /**  */
  PriceEachItem?: number;

  /**  */
  TotalProduct?: number;

  /**  */
  ContractID?: number;
}

export interface GeneralCommentView {
  /**  */
  Id?: number;

  /**  */
  Note?: string;

  /**  */
  MediaId?: number;

  /**  */
  CampainId?: number;

  /**  */
  TypeId?: number;

  /**  */
  CreatedTime?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;
}

export interface GeneralCommentViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: GeneralCommentView[];
}

export interface GetDataCustomerModel {
  /**  */
  Id?: number;

  /**  */
  MediaId?: number;

  /**  */
  CampainId?: number;

  /**  */
  ServiceId?: number;

  /**  */
  ServiceName?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  FullName?: string;

  /**  */
  Phone?: string;

  /**  */
  ExpenseData?: number;

  /**  */
  ExpenseHeadUp?: number;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  Comment?: string;

  /**  */
  Revenue?: number;

  /**  */
  Age?: string;

  /**  */
  Gender?: number;

  /**  */
  TelesalesId?: number;

  /**  */
  TelesalesName?: string;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  NameMedia?: string;

  /**  */
  CreatedAtMedia?: Date;

  /**  */
  MediaUserId?: number;

  /**  */
  MediaUserName?: string;

  /**  */
  CreatedAtCampain?: Date;

  /**  */
  ListService?: DataCustomerServiceModel[];

  /**  */
  ListProduct?: DataCustomerProductModel[];

  /**  */
  Timer?: Date;

  /**  */
  MediaCode?: string;

  /**  */
  PaymentContractName?: string;

  /**  */
  PaymentMoney?: number;

  /**  */
  NumberCard?: string;

  /**  */
  ChatPageId?: number;

  /**  */
  ChatPageName?: string;

  /**  */
  LinkFacebook?: string;

  /**  */
  Note?: string;
}

export interface GetDataCustomerModelListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: GetDataCustomerModel[];
}

export interface HomeReportAds {
  /** ID ADS */
  MediaUserID?: number;

  /** Tên ADS */
  AdsUserName?: string;

  /** Tổng doanh thu */
  TotalMoney?: number;

  /** doanh thu tháng */
  TotalMoneyMonth?: number;

  /** Dịch vụ */
  ServiceName?: string;

  /** ID dịch vụ */
  SourceService?: number;

  /** Trung Bình Bill tháng */
  BillMonth?: number;

  /** Trung Bình Bill */
  TotalBill?: number;

  /** Tổng khách */
  TotalLoan?: number;

  /** Tổng chi phí khách */
  TotalExpense?: number;

  /** Tổng chi phí khách tháng */
  TotalExpenseMonth?: number;

  /** Tổng chi phí data */
  TotalExpenseData?: number;

  /** chi phí data */
  TotalExpenseDataMonth?: number;

  /** tổng số data */
  TotalData?: number;

  /** tổng số data hẹn */
  TotalAppointment?: number;

  /** Tỉ lệ data - khách */
  PercentDataCustomer?: number;

  /** Tỉ lệ data - hẹn */
  PercentDataAppointment?: number;

  /** Tổng tỉ xuất lợi nhuận */
  PercentRevenue?: number;

  /**  */
  PercentRevenueMonth?: number;

  /** Tổng số message */
  TotalMessage?: number;

  /** Chi phí message */
  TotalMoneyMessage?: number;

  /** Tỉ lệ mess - data */
  PercentMessageData?: number;

  /** Tổng đã thu */
  PaymentMoney?: number;

  /** Tổng đã thu tháng */
  PaymentMoneyMonth?: number;

  /**  */
  TotalDataMonth?: number;
}

export interface HomeReportAdsListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: HomeReportAds[];
}

export interface HomeSaleReport {
  /** ID SALE */
  SaleID?: number;

  /** Tên sale */
  SaleName?: string;

  /** Dịch vụ */
  ServiceName?: string;

  /** Tổng doanh thu */
  TotalMoney?: number;

  /** doanh thu tháng */
  TotalMoneyMonth?: number;

  /** Tổng HĐ */
  TotalLoan?: number;

  /** HĐ tháng */
  TotalLoanMonth?: number;

  /** Tổng đã thu */
  PaymentMoney?: number;

  /** Tổng đã thu tháng */
  PaymentMoneyMonth?: number;

  /** Tổng chi phí */
  TotalExpense?: number;

  /** Tổng chi phí tháng */
  TotalExpenseMonth?: number;

  /** doanh thu phễu */
  TotalMoneySource?: number;

  /** doanh thu up chéo */
  TotalMoneyOther?: number;

  /** trung bình phễu */
  PercentMoneySource?: number;

  /** trung bình up chéo */
  PercentMoneyOther?: number;

  /**  */
  SourceService?: number;

  /** Tổng tỉ xuất lợi nhuận */
  PercentRevenue?: number;

  /**  */
  PercentRevenueMonth?: number;
}

export interface HomeSaleReportListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: HomeSaleReport[];
}

export interface ImportProductView {
  /**  */
  Id?: number;

  /**  */
  ProductId?: number;

  /**  */
  ProductName?: string;

  /**  */
  ProductCode?: string;

  /**  */
  Amount?: number;

  /**  */
  Money?: number;

  /**  */
  StaffId?: number;

  /**  */
  StaffName?: string;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  AmountSell?: number;

  /**  */
  MoneySell?: number;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  SourcePhone?: string;

  /**  */
  Infomation?: string;
}

export interface ImportProductViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ImportProductView[];
}

export interface JSTreeModel {
  /** ID quyền */
  id?: string;

  /** Miêu tả quyền */
  text?: string;

  /**  */
  state?: State;

  /** Object con */
  children?: JSTreeModel[];
}

export interface JSTreeModelListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: JSTreeModel[];
}

export interface MarketingView {
  /**  */
  ID?: number;

  /**  */
  Fullname?: string;

  /**  */
  Phone?: string;

  /**  */
  Note?: string;

  /**  */
  UserId?: number;

  /**  */
  ShopId?: number;

  /**  */
  Status?: number;

  /**  */
  CreatedDate?: Date;

  /**  */
  UpdatedDate?: Date;

  /**  */
  UpdatedBy?: number;

  /**  */
  SaleID?: number;

  /**  */
  AdsID?: number;

  /**  */
  Age?: number;

  /**  */
  Gender?: number;

  /**  */
  Facebook?: string;

  /**  */
  SourceService?: number;

  /**  */
  MarketingActionId?: number;

  /**  */
  Media?: string;

  /**  */
  Page?: string;

  /**  */
  CampainId?: number;

  /**  */
  CreatedBy?: string;

  /**  */
  MarketingUserID?: number;

  /**  */
  MarketingUserName?: string;

  /**  */
  LastMarketingActionName?: string;

  /**  */
  LastMarketingActionComment?: string;

  /**  */
  LastMarketingActionCreatedDate?: Date;

  /**  */
  StatusComment?: string;

  /**  */
  SourceName?: string;

  /**  */
  SaleName?: string;

  /**  */
  ADSName?: string;

  /**  */
  CampainName?: string;
}

export interface MarketingViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MarketingView[];
}

export interface MenuPermission {
  /**  */
  title?: string;

  /**  */
  visible?: boolean;

  /**  */
  children?: MenuPermission[];

  /**  */
  path?: string;

  /**  */
  position?: number;
}

export interface MetaAds {
  /** ID ads */
  AdsID?: number;

  /** Tên ads */
  AdsName?: string;
}

export interface MetaAdsListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaAds[];
}

export interface MetaBank {
  /** ID ngân hàng */
  BankID?: number;

  /** Tên ngân hàng */
  Code?: string;

  /** Tên ngân hàng */
  NameBank?: string;
}

export interface MetaBankListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaBank[];
}

export interface MetaCampain {
  /** ID chiến dịch */
  Id?: number;

  /** Id Media */
  MediaId?: number;
}

export interface MetaCampainListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaCampain[];
}

export interface MetaCity {
  /** ID city */
  CityID?: number;

  /** Tên thành phố */
  Name?: string;

  /** Loại */
  TypeCity?: string;
}

export interface MetaCityListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaCity[];
}

export interface MetaContract {
  /**  */
  Id?: number;
}

export interface MetaContractListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaContract[];
}

export interface MetaDataCustomer {
  /** ID khách hàng */
  Id?: number;

  /** Tên khách hàng */
  FullName?: string;

  /**  */
  Phone?: string;
}

export interface MetaDataCustomerListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaDataCustomer[];
}

export interface MetaDepartment {
  /** ID phòng ban */
  Id?: number;

  /** Tên phòng ban */
  Name?: string;
}

export interface MetaDepartmentListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaDepartment[];
}

export interface MetaDistrict {
  /** ID district */
  DistrictID?: number;

  /** Tên Quận/Huyện */
  Name?: string;

  /** Loại */
  TypeDistrict?: string;

  /** ID cỉy */
  CityId?: number;
}

export interface MetaDistrictListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaDistrict[];
}

export interface MetaMedia {
  /** ID media */
  Id?: number;

  /** ID dịch vụ */
  ServiceId?: number;

  /** Url */
  Url?: string;

  /** ID nhân viên */
  MediaUserId?: number;

  /**  */
  Code?: string;
}

export interface MetaMediaListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaMedia[];
}

export interface MetaPosition {
  /** ID vị trí */
  Id?: number;

  /** Tên vị trí */
  Name?: string;
}

export interface MetaPositionListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaPosition[];
}

export interface MetaSourceCustomer {
  /** ID nguồn khách */
  Id?: number;

  /** Tên nguồn khách */
  Name?: string;
}

export interface MetaSourceCustomerListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaSourceCustomer[];
}

export interface MetaStaff {
  /** UserID */
  UserID?: number;

  /** Tài khoản nhân viên */
  UserName?: string;
}

export interface MetaStaffByGroup {
  /**  */
  UserID?: number;

  /** Tài khoản nhân viên */
  UserName?: string;

  /** Tên nhân viên */
  FullName?: string;
}

export interface MetaStaffByGroupListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaStaffByGroup[];
}

export interface MetaStaffListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaStaff[];
}

export interface MetaWallet {
  /** ID ví */
  Id?: number;

  /** tên ví */
  Name?: string;
}

export interface MetaWalletListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: MetaWallet[];
}

export interface PaymentContractCustomize {
  /** ID HĐ */
  ContractID?: number;

  /** ID vis */
  WalletID?: number;

  /** Tiền Khách trả */
  CustomerPay?: number;

  /** Tiền lãi */
  InterestMoney?: number;

  /** Tiền khác */
  OtherMoney?: number;

  /** Từ ngày */
  FromDate?: Date;

  /** Đến ngày */
  ToDate?: Date;
}

export interface PaymentProductAdd {
  /** Id sản phẩm */
  ProductId?: number;

  /** Số lượng */
  Amount?: number;

  /** Giá tiền */
  Money?: number;

  /** Tổng tiền */
  TotalMoney?: number;

  /** SĐT nguồn */
  PhoneSource?: string;

  /** Thông tin sản phẩm */
  Infomation?: string;
}

export interface ProductView {
  /**  */
  Id?: number;

  /**  */
  Name?: string;

  /**  */
  Code?: string;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  Amount?: number;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;
}

export interface ProductViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ProductView[];
}

export interface ReportHomeService {
  /** Tổng doanh thu */
  TotalMoney?: number;

  /** doanh thu tháng */
  TotalMoneyMonth?: number;

  /** Dịch vụ */
  ServiceName?: string;

  /** ID dịch vụ */
  SourceService?: number;

  /** Trung Bình Bill tháng */
  BillMonth?: number;

  /** Trung Bình Bill */
  TotalBill?: number;

  /** Tổng khách */
  TotalLoan?: number;

  /** Tổng chi phí khách */
  TotalExpense?: number;

  /** Tổng chi phí khách tháng */
  TotalExpenseMonth?: number;

  /** Tổng chi phí data */
  TotalExpenseData?: number;

  /** chi phí data */
  TotalExpenseDataMonth?: number;

  /** tổng số data */
  TotalData?: number;

  /** tổng số data hẹn */
  TotalAppointment?: number;

  /** Tỉ lệ data - khách */
  PercentDataCustomer?: number;

  /** Tỉ lệ data - hẹn */
  PercentDataAppointment?: number;

  /** Tổng tỉ xuất lợi nhuận */
  PercentRevenue?: number;

  /**  */
  PercentRevenueMonth?: number;

  /** Tổng số message */
  TotalMessage?: number;

  /** Chi phí message */
  TotalMoneyMessage?: number;

  /** Tỉ lệ mess - data */
  PercentMessageData?: number;

  /** Tổng đã thu */
  PaymentMoney?: number;

  /** Tổng đã thu tháng */
  PaymentMoneyMonth?: number;

  /**  */
  TotalDataMonth?: number;
}

export interface ReportHomeServiceListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ReportHomeService[];
}

export interface ReportMarketing {
  /**  */
  Message?: number;

  /**  */
  DataCount?: number;

  /**  */
  DataAppointment?: number;

  /**  */
  DataDone?: number;

  /**  */
  PercentAppointment?: number;

  /**  */
  PercentDone?: number;

  /**  */
  PercentMess?: number;

  /**  */
  ExpenseMoney?: number;

  /**  */
  AdsMoney?: number;

  /**  */
  PriceAds?: number;

  /**  */
  PriceData?: number;

  /**  */
  TotalPriceCustomer?: number;

  /**  */
  TotalCustomerADSPrice?: number;

  /**  */
  DataHot?: number;

  /**  */
  DataNormal?: number;

  /**  */
  DataCool?: number;

  /**  */
  ChartMarketings?: ChartMarketing[];

  /**  */
  PaymentMoney?: number;
}

export interface ReportMarketingResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ReportMarketing;
}

export interface ReportProduct {
  /**  */
  Id?: number;

  /**  */
  Name?: string;

  /**  */
  Code?: string;

  /**  */
  Amount?: number;

  /**  */
  Money?: number;

  /**  */
  UserId?: number;

  /**  */
  UserName?: string;

  /**  */
  Date?: Date;

  /**  */
  CustomerPhone?: string;

  /**  */
  StatusName?: string;

  /**  */
  CustomerName?: string;
}

export interface ReportProductListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ReportProduct[];
}

export interface ReportSale {
  /**  */
  TotalMoney?: number;

  /**  */
  TotalMoneyDay?: number;

  /**  */
  TotalMoneyCurrent?: number;

  /**  */
  TotalMoneyCurrentDay?: number;

  /**  */
  TotalMoneyMonth?: number;

  /**  */
  TotalMoneyCurrentMonth?: number;

  /**  */
  TotalExpenseCustomer?: number;

  /**  */
  TotalExpenseOther?: number;

  /**  */
  RevenuePercent?: number;

  /**  */
  TotalMoneySourceService?: number;

  /**  */
  TotalMoneyOtherService?: number;

  /**  */
  ChartSales?: ChartMarketing[];

  /**  */
  TotalPaymentMoney?: number;

  /**  */
  TotalPaymentMoneyMonth?: number;

  /**  */
  TotalContractMonth?: number;

  /**  */
  PercentBillAll?: number;

  /**  */
  PercentBillMonth?: number;

  /**  */
  TotalContractDay?: number;

  /**  */
  TotalPaymentMoneyDay?: number;

  /**  */
  PercentBillDay?: number;

  /**  */
  PaymentOther?: number;
}

export interface ReportSaleResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ReportSale;
}

export interface ReportTelesaleHome {
  /** ID SALE */
  TelesalesId?: number;

  /** Tên sale */
  TeleSaleName?: string;

  /** Dịch vụ */
  ServiceName?: string;

  /** Tổng doanh thu */
  TotalMoney?: number;

  /** doanh thu tháng */
  TotalMoneyMonth?: number;

  /** Tổng khách */
  TotalLoan?: number;

  /** HĐ tháng */
  TotalLoanMonth?: number;

  /** Tổng đã thu */
  PaymentMoney?: number;

  /** Tổng đã thu tháng */
  PaymentMoneyMonth?: number;

  /** Tổng chi phí */
  TotalExpense?: number;

  /** Tổng chi phí tháng */
  TotalExpenseMonth?: number;

  /**  */
  SourceService?: number;

  /** Tổng tỉ xuất lợi nhuận */
  PercentRevenue?: number;

  /**  */
  PercentRevenueMonth?: number;

  /** tổng số data */
  TotalData?: number;

  /** tổng số data hẹn */
  TotalAppointment?: number;

  /** Tỉ lệ data - khách */
  PercentDataCustomer?: number;

  /** Tỉ lệ data - hẹn */
  PercentDataAppointment?: number;

  /**  */
  TotalExpenseData?: number;
}

export interface ReportTelesaleHomeListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ReportTelesaleHome[];
}

export interface ReportTotalProduct {
  /**  */
  ImportAmount?: number;

  /**  */
  ImportMoney?: number;

  /**  */
  ExportAmount?: number;

  /**  */
  ExportMoney?: number;
}

export interface ReportTotalProductResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ReportTotalProduct;
}

export interface ReportTransactionExpense {
  /**  */
  TypeId?: number;

  /**  */
  TypeName?: string;

  /**  */
  TotalMoney?: number;

  /**  */
  Count?: number;

  /**  */
  FundBeginning?: number;

  /**  */
  FundEnding?: number;
}

export interface ReportTransactionExpenseListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ReportTransactionExpense[];
}

export interface RequestCloseContract {
  /** ID HĐ */
  ContractID?: number;

  /** Tiền khác */
  OtherMoney?: number;

  /** Đến ngày */
  CloseDate?: Date;
}

export interface RequestExtendTime {
  /**  */
  ContractID?: number;

  /**  */
  TimeExtend?: number;

  /**  */
  Note?: string;
}

export interface RequestInsertLoanExtra {
  /** ID HĐ */
  ContractID?: number;

  /** ID vis */
  WalletID?: number;

  /** Tiền Khách trả */
  TotalMoney?: number;

  /** Ngày trả gốc */
  DateExtra?: Date;

  /** Ghi chú */
  Note?: string;

  /** Hen ngay dong goc tiep theo */
  NextDateExtra?: Date;

  BankType?: number;

}

export interface ResponseData {
  /**  */
  Result?: number;

  /**  */
  Message?: string;

  /**  */
  Data?: any | null;

  /**  */
  TotalData?: any | null;

  /**  */
  TotalCount?: number;
}

export interface SelectModel {
  /** ID bản ghi */
  ID?: number;

  /** Value Select */
  Text?: string;
}

export interface SelectModelListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: SelectModel[];
}

export interface SelectProduct {
  /**  */
  Id?: number;

  /**  */
  Name?: string;

  /**  */
  Code?: string;
}

export interface SelectProductListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: SelectProduct[];
}

export interface ServiceFeeDetailsModel {
  /**  */
  TransactionId?: number;

  /**  */
  ContractId?: number;

  /**  */
  CampianId?: number;

  /**  */
  MediaId?: number;

  /**  */
  MediaCode?: string;

  /**  */
  DataCustomerId?: number;

  /**  */
  CreatedCampainAt?: Date;

  /**  */
  CreatedMediaAt?: Date;

  /**  */
  CreatedContractAt?: Date;

  /**  */
  CreatedDataCustomerAt?: Date;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  CustomerName?: string;

  /**  */
  TotalMoney?: number;

  /**  */
  CollectionBalanceMoney?: number;

  /**  */
  InterestMoney?: number;

  /**  */
  Note?: string;

  /**  */
  SumTotalMoney?: number;

  /**  */
  SumCollectionBalanceMoney?: number;

  /**  */
  SumInterestMoney?: number;

  /**  */
  ListService?: DataCustomerServiceModel[];

  /**  */
  TelesaleName?: string;

  /**  */
  SaleName?: string;

  /**  */
  MediaUserName?: string;

  /**  */
  SourceService?: number;

  /**  */
  SourceServiceName?: string;

  /**  */
  MediaUserID?: number;

  /**  */
  SaleID?: number;

  /**  */
  TelesaleID?: number;

  /**  */
  TypeID?: number;

  BankTypeName?: string;

}

export interface ServiceFeeDetailsModelListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: ServiceFeeDetailsModel[];
}

export interface ShopUserLogin {
  /** SHOPID */
  ShopID?: number;

  /** Tên cửa hàng */
  ShopName?: string;

  /** Địa chỉ */
  Address?: string;
}

export interface SourceCustomerView {
  /**  */
  Id?: number;

  /**  */
  Name?: string;
}

export interface SourceCustomerViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: SourceCustomerView[];
}

export interface State {
  /** đóng hay mở tree */
  opened?: boolean;

  /** disable hay k */
  disabled?: boolean;

  /** Tích hay k */
  selected?: boolean;
}

export interface TransactionView {
  /**  */
  Id?: number;

  /**  */
  CustomerName?: string;

  /**  */
  Money?: number;

  /**  */
  WalletId?: number;

  /**  */
  WalletName?: string;

  /**  */
  Note?: string;

  /**  */
  TypeId?: number;

  /**  */
  TypeName?: string;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  PaymentTypeId?: number;

  /**  */
  PaymentTypeName?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  TotalMoney?: number;

  /**  */
  ActionPaymentId?: number;

  /**  */
  ActionPaymentName?: string;

  /**  */
  PathImage?: string;
}

export interface TransactionViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: TransactionView[];
}

export interface UserPermissionCollectExpenseExpect {
  /**  */
  ConfigCollectionExpenseID?: number;

  /**  */
  ConfigName?: string;
}

export interface UserPermissionCollectExpenseExpectListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: UserPermissionCollectExpenseExpect[];
}

export interface WalletTransferView {
  /**  */
  Id?: number;

  /**  */
  ShopId?: number;

  /**  */
  ShopName?: string;

  /**  */
  FromWalletId?: number;

  /**  */
  FromWalletName?: string;

  /**  */
  ToWalletId?: number;

  /**  */
  ToWalletName?: string;

  /**  */
  Money?: number;

  /**  */
  Note?: string;

  /**  */
  Status?: number;

  /**  */
  StatusName?: string;

  /**  */
  CreatedAt?: Date;

  /**  */
  CreatedBy?: number;

  /**  */
  CreatedByName?: string;

  /**  */
  VerifyUserId?: number;

  /**  */
  VerifyUserName?: string;

  /**  */
  WithdrawMoney?: number;
}

export interface WalletTransferViewListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  Data?: WalletTransferView[];
}
