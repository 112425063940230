import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Drawer,
  Dropdown,
  Grid,
  Layout,
  Menu,
  Row,
  Space,
  Tooltip,
} from "antd";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  FormOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShopOutlined,
  UserOutlined,
  DesktopOutlined,
  NotificationOutlined,
  LockOutlined,
  FileSyncOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  ScheduleOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { withAuth } from "../hooks/withAuth";
import { pathname } from "../routers/path";
import { toastSuccess } from "../utils/toast";
import { AppShopView, DataCustomerService } from "../services";
import { formatMoney, getShop, getUser } from "../utils";
// import { ReactComponent as Logo } from "../assets/images/logo-spa-inkythuatso.svg";
import { useDispatch, useSelector } from "react-redux";
import appActions from "../hooks/app/actions";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "../constants";
import { serviceOptions } from "../services/serviceOptions";
import { MdPayment } from "react-icons/md";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export interface ISidebarItem {
  title: string;
  icon?: JSX.Element;
  visible?: boolean;
  path?: string;
  children?: ISidebarItem[];
}

function CMSLayout() {
  const {
    data,
    countCustomerAppointment,
    countTransfer,
    countContract,
    money,
    countDaySchedule,
    countPaymentSchedule,
  } = useSelector((state: any) => state.App);
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[] | undefined>([]);
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const routes = [
    {
      title: "Quản lý tài khoản",
      icon: <DesktopOutlined />,
      visible: getUser()?.GroupID === 1,
      path: "/superadmin",
    },
    {
      title: "Quản trị viên",
      icon: <DesktopOutlined />,
      visible: getUser()?.GroupID === 3 || getUser()?.GroupID === 1,
      path: "/admin",
    },
    ...getUser()?.menuPermissions.map((item: any) => ({
      ...item,
      icon: <DesktopOutlined />,
    })),
  ];

  const submenuChild = (obj: ISidebarItem) => {
    if (obj.children && obj.children.length > 0) {
      const cHtml = obj.children.map((item) => {
        return submenuChild(item);
      });
      return (
        obj.visible && (
          <SubMenu key={obj.title} title={obj.title} icon={obj.icon}>
            {cHtml}
          </SubMenu>
        )
      );
    } else {
      return (
        obj.visible && (
          <Menu.Item key={obj.path} icon={obj.icon}>
            <Link to={obj?.path || "#"}>{obj.title}</Link>
          </Menu.Item>
        )
      );
    }
  };

  useEffect(() => {
    if (!routes.find((item: any) => item.path === location.pathname)) {
      if (
        routes.find(
          (item: any) =>
            item.children &&
            item.children.find((item: any) => item.path === location.pathname)
        )
      ) {
        const currentSubItem = routes.find(
          (item: any) =>
            item.children &&
            item.children.find((item: any) => item.path === location.pathname)
        );
        if (currentSubItem?.title) setOpenKeys([currentSubItem.title]);
      }
      return;
    }
  }, [location.pathname]);

  const menu = routes.map((obj: any) => {
    if (obj.children && obj.children.length > 0) {
      return submenuChild(obj);
    } else {
      return (
        obj.visible && (
          <Menu.Item key={obj.path} icon={obj.icon}>
            <Link to={obj?.path || "#"}>{obj.title}</Link>
          </Menu.Item>
        )
      );
    }
  });

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleOpenChangeMenu = (openKeys: React.Key[]) => {
    setOpenKeys(openKeys as string[]);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const drawerOnClose = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    dispatch(appActions.getCountContract());
    dispatch(appActions.getCountTransfer());
    dispatch(appActions.getDaySchedule());
    dispatch(appActions.getPaymentSchedule());
    dispatch(appActions.getAds());
    dispatch(appActions.getTelesales());
    dispatch(appActions.getShop());
    dispatch(appActions.getMoney());
    dispatch(appActions.getStaffShop());
    dispatch(appActions.getSales());
    dispatch(appActions.getAppointment());
  }, [location.pathname]);

  const logout = () => {
    localStorage.clear();
    navigate(pathname.login, { replace: true });
  };

  const changeShop = (shop: any) => {
    const token = localStorage.getItem("token");
    localStorage.setItem("shop", JSON.stringify(shop));
    const axiosConfig: AxiosRequestConfig = {
      baseURL: BASE_URL,
      timeout: 60000, // 1 phút
    };
    axiosConfig.headers = {
      //@ts-ignore
      Token: token,
      "Access-Control-Allow-Origin": "*",
      UserID: getUser()?.UserID,
      ShopID: shop?.ShopID,
    };
    serviceOptions.axios = axios.create(axiosConfig);
    toastSuccess("Thay đổi cửa hàng thành công");
    navigate(pathname.root);
    dispatch(appActions.getMoney());
  };

  const [countRecall, setCountRecall] = useState<any>(0);
  useEffect(() => {
    DataCustomerService.getDataCustomerReCall().then((response) => {
      if (response.Result === 1) {
        setCountRecall(response.Data?.length);
      }
    });
  }, [location.pathname]);

  return (
    <Layout>
      {screens.md ? (
        <Sider
          trigger={null}
          breakpoint="lg"
          collapsible
          collapsed={collapsed}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          {/* <div className="slider-logo">
            {collapsed ? <Logo height={50} /> : <Logo height={50} />}
          </div> */}
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[location.pathname]}
            openKeys={openKeys}
            onOpenChange={handleOpenChangeMenu}
          >
            {menu}
          </Menu>
        </Sider>
      ) : (
        <Drawer
          placement={"left"}
          closable={false}
          open={drawerVisible}
          onClose={drawerOnClose}
          bodyStyle={{
            padding: 0,
            background: "#001529",
          }}
        >
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[location.pathname]}
            openKeys={openKeys}
            onOpenChange={handleOpenChangeMenu}
            onClick={() => {
              if (!screens.md) {
                drawerOnClose();
              }
            }}
          >
            {menu}
          </Menu>
        </Drawer>
      )}
      <Layout
        style={{
          marginLeft: !screens.md ? 0 : collapsed ? 80 : 200,
          transition: "all 0.2s",
        }}
      >
        <Header className="header">
          <Row
            justify="space-between"
            style={{
              boxShadow: "0 1px 4px rgb(0 21 41 / 10%)",
              padding: "4px 10px",
            }}
          >
            <Col>
              <Space>
                {/* {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: !screens.md ? showDrawer : handleCollapse,
                  }
                )} */}
                <Button
                  onClick={() => {
                    if (!screens.md) {
                      showDrawer();
                    } else {
                      handleCollapse();
                    }
                  }}
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                />
              </Space>
            </Col>
            {screens.md ? (
              <Col>
                <Space>
                  <Tooltip title="Danh sách hẹn gọi lại">
                    <Badge
                      count={countRecall}
                      style={{ marginRight: 15 }}
                      showZero
                    >
                      <Button
                        onClick={() => navigate("/recall")}
                        shape="circle"
                        style={{ marginRight: 15 }}
                        icon={<PhoneOutlined />}
                      />
                    </Badge>
                  </Tooltip>
                  <Tooltip title="Danh sách khách hẹn thanh toán">
                    <Badge
                      count={countCustomerAppointment}
                      style={{ marginRight: 15 }}
                      showZero
                    >
                      <Button
                        onClick={() => navigate("/customer-appointment")}
                        shape="circle"
                        style={{ marginRight: 15 }}
                        icon={<MdPayment />}
                      />
                    </Badge>
                  </Tooltip>
                  <Tooltip title="Danh sách đến ngày thanh toán">
                    <Badge
                      count={countPaymentSchedule}
                      style={{ marginRight: 15 }}
                      showZero
                    >
                      <Button
                        onClick={() => navigate("/customer/daypayment")}
                        shape="circle"
                        style={{ marginRight: 15 }}
                        icon={<MdPayment />}
                      />
                    </Badge>
                  </Tooltip>
                  <Tooltip title="Danh sách hẹn trong ngày">
                    <Badge
                      count={countDaySchedule}
                      style={{ marginRight: 15 }}
                      showZero
                    >
                      <Button
                        onClick={() => navigate("/customer/dayschedule")}
                        shape="circle"
                        style={{ marginRight: 15 }}
                        icon={<ScheduleOutlined />}
                      />
                    </Badge>
                  </Tooltip>
                  <Tooltip title="Điều chuyển ví">
                    <Badge
                      count={countTransfer}
                      style={{ marginRight: 15 }}
                      showZero
                    >
                      <Button
                        onClick={() => navigate("/wallet/transfer")}
                        shape="circle"
                        style={{ marginRight: 15 }}
                        icon={<FileSyncOutlined />}
                      />
                    </Badge>
                  </Tooltip>
                  <Tooltip title="Hồ sơ đến kỳ điều trị">
                    <Badge
                      count={countContract}
                      style={{ marginRight: 15 }}
                      showZero
                    >
                      <Button
                        onClick={() => navigate("/contract/treatment")}
                        shape="circle"
                        style={{ marginRight: 15 }}
                        icon={<ClockCircleOutlined />}
                      />
                    </Badge>
                  </Tooltip>
                  <Button icon={<DollarCircleOutlined />}>
                    {formatMoney(money)}
                  </Button>
                  <Dropdown
                    overlay={
                      <Menu>
                        {data?.map((item: AppShopView) => (
                          <Menu.Item onClick={() => changeShop(item)}>
                            <Link to={"#"} className="flex items-center">
                              <ShopOutlined className="mr-2" />
                              {item.ShopName}
                            </Link>
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <Tooltip placement="leftTop" title="Chuyển cửa hàng khác">
                      <Button icon={<ShopOutlined />}>
                        {getShop()?.ShopName}
                      </Button>
                    </Tooltip>
                  </Dropdown>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <Link
                            to={pathname.profile}
                            className="flex items-center"
                          >
                            <Space>
                              <FormOutlined />
                              Đổi thông tin
                            </Space>
                          </Link>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item>
                          <Link
                            to={pathname.profile}
                            className="flex items-center"
                          >
                            <Space>
                              <LockOutlined /> Đổi mật khẩu
                            </Space>
                          </Link>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          key="setting:2"
                          className="flex items-center"
                          onClick={() => logout()}
                        >
                          <Link to={"#"} className="flex items-center">
                            <Space>
                              <LogoutOutlined /> Đăng xuất
                            </Space>
                          </Link>
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottom"
                  >
                    <Button icon={<UserOutlined />}>
                      {getUser()?.UserName}
                    </Button>
                  </Dropdown>
                </Space>
              </Col>
            ) : (
              <Col>
                <Space>
                  <Button icon={<DollarCircleOutlined />}>
                    {formatMoney(money)}
                  </Button>
                  <Dropdown
                    overlay={
                      <Menu>
                        {data?.map((item: AppShopView) => (
                          <>
                            <Menu.Item onClick={() => changeShop(item)}>
                              <Link to={"#"} className="flex items-center">
                                <ShopOutlined className="mr-2" />
                                {item.ShopName}
                              </Link>
                            </Menu.Item>
                            <Menu.Divider />
                          </>
                        ))}
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <Tooltip placement="leftTop" title="Chuyển cửa hàng khác">
                      <Button icon={<ShopOutlined />}>
                        {getShop()?.ShopName}
                      </Button>
                    </Tooltip>
                  </Dropdown>
                  <Dropdown
                    overlay={
                      <Menu
                        style={{ minWidth: 250 }}
                        items={[
                          {
                            key: "5",
                            label: "KH khách hẹn thanh toán",
                            icon: <ClockCircleOutlined />,
                            onClick: () => navigate("/customer-appointment"),
                          },
                          {
                            key: "4",
                            label: "KH đến ngày thanh toán",
                            icon: <ClockCircleOutlined />,
                            onClick: () => navigate("/customer/daypayment"),
                          },
                          {
                            key: "3",
                            label: "Khách hàng đã hẹn",
                            icon: <ClockCircleOutlined />,
                            onClick: () => navigate("/customer/dayschedule"),
                          },
                          {
                            key: "2",
                            label: "Điều chuyển ví",
                            icon: <ClockCircleOutlined />,
                            onClick: () => navigate("/wallet/transfer"),
                          },
                          {
                            key: "1",
                            label: "Hồ sơ đến kỳ điều trị",
                            icon: <ClockCircleOutlined />,
                            onClick: () => navigate("/contract/treatment"),
                          },
                          {
                            key: "8",
                            label: getUser()?.UserName,
                            icon: <UserOutlined />,
                            children: [
                              {
                                key: "8-1",
                                label: "Đổi thông tin",
                                onClick: () => navigate(pathname.profile),
                              },
                              {
                                key: "8-2",
                                label: "Đổi mật khẩu",
                                onClick: () => navigate(pathname.profile),
                              },
                            ],
                          },
                          {
                            key: "9",
                            label: "Đăng xuất",
                            icon: <LogoutOutlined />,
                            onClick: () => logout(),
                          },
                        ]}
                      ></Menu>
                    }
                    placement="bottom"
                  >
                    <Button icon={<NotificationOutlined />} />
                  </Dropdown>
                </Space>
              </Col>
            )}
          </Row>
        </Header>
        <Content style={{ backgroundColor: "#ffffff" }}>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center", backgroundColor: "#ffffff" }}>
          ©2023 Hệ thống quản lý{" "}
          <strong style={{ color: "#5f57c3" }}>SHINY</strong>
        </Footer>
      </Layout>
    </Layout>
  );
}
export default withAuth(CMSLayout);
