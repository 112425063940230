import React, { useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@ant-design/flowchart/dist/index.css";
import Router from "./routers";
import { getMessagingToken, onMessageListener } from "./firebase";
import { useDispatch } from "react-redux";
import appActions from "./hooks/app/actions";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    getMessagingToken();
    dispatch(appActions.getShop());
    dispatch(appActions.getCountContract());
    dispatch(appActions.getCountTransfer());
    dispatch(appActions.getDaySchedule());
    dispatch(appActions.getPaymentSchedule());
    dispatch(appActions.getAds());
    dispatch(appActions.getTelesales());
    dispatch(appActions.getMoney());
    dispatch(appActions.getStaffShop());
    dispatch(appActions.getSales());
    dispatch(appActions.getAppointment());
  }, []);
  useEffect(() => {
    onMessageListener().then((data) => {
      console.log("Receive foreground: ", data);
    });
  });
  return (
    <>
      <Router />
      <ToastContainer
        className="toast-position"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;

// if (err.response.status === 401) {
//   localStorage.clear()
//   window.location.href = '/auth/login'
// }
