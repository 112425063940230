import actions from "./actions";
const initState = {
  isLoading: false,
  data: [],
  total: 0,
  countTransfer: 0,
  countContract: 0,
  money: 0,
  countDaySchedule: 0,
  countPaymentSchedule: 0,
  countCustomerAppointment: 0,
  listAds: [],
  listTelesales: [],
  listStaffShop: [],
  listSales: [],
};

export default function appReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.GET_SHOP:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_SHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.result,
      };

    case actions.GET_SHOP_ERROR:
      return {
        ...state,
        isLoading: false,
        data: [],
      };

    case actions.GET_COUNT_TRANSFER:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_COUNT_TRANSFER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countTransfer: action.result,
      };

    case actions.GET_COUNT_TRANSFER_ERROR:
      return {
        ...state,
        isLoading: false,
        countTransfer: 0,
      };

    case actions.GET_COUNT_CONTRACT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_COUNT_CONTRACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countContract: action.result,
      };

    case actions.GET_COUNT_CONTRACT_ERROR:
      return {
        ...state,
        isLoading: false,
        countContract: 0,
      };

    case actions.GET_MONEY:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_MONEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        money: action.result,
      };

    case actions.GET_MONEY_ERROR:
      return {
        ...state,
        isLoading: false,
        money: 0,
      };
    case actions.GET_DAY_SCHEDULE:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_DAY_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countDaySchedule: action.result,
      };

    case actions.GET_DAY_SCHEDULE_ERROR:
      return {
        ...state,
        isLoading: false,
        countDaySchedule: 0,
      };

    case actions.GET_PAYMENT_SCHEDULE:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_PAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countPaymentSchedule: action.result,
      };

    case actions.GET_PAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        isLoading: false,
        countPaymentSchedule: 0,
      };

    case actions.GET_ADS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_ADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listAds: action.result,
      };

    case actions.GET_ADS_ERROR:
      return {
        ...state,
        isLoading: false,
        listAds: [],
      };

    case actions.GET_TELESALES:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_TELESALES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listTelesales: action.result,
      };

    case actions.GET_TELESALES_ERROR:
      return {
        ...state,
        isLoading: false,
        listTelesales: [],
      };

    case actions.GET_SALES:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_SALES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listSales: action.result,
      };

    case actions.GET_SALES_ERROR:
      return {
        ...state,
        isLoading: false,
        listSales: [],
      };

    case actions.GET_STAFF_SHOP:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_STAFF_SHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listStaffShop: action.result,
      };

    case actions.GET_STAFF_SHOP_ERROR:
      return {
        ...state,
        isLoading: false,
        listStaffShop: [],
      };

    case actions.GET_CUSTOMER_APPOINTMENT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_CUSTOMER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countCustomerAppointment: action.result,
      };

    case actions.GET_CUSTOMER_APPOINTMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        countCustomerAppointment: 0,
      };

    default:
      return state;
  }
}
