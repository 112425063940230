import { toast } from "react-toastify"

const toastError = (message: string | undefined) => {
    toast.error(message)
}

const toastSuccess = (message: string | undefined) => {
    toast.success(message)
}

const error = () => {
    toast.error('Có lỗi xảy ra. Vui lòng thử lại sau!')
}

export {
    toastError, toastSuccess, error
}