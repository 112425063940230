import moment from "moment"


export const SheetJSFT = [
    'xlsx',
    'xlsb',
    'xlsm',
    'xls',
    'xml',
    'csv',
    'txt',
    'ods',
    'fods',
    'uos',
    'sylk',
    'dif',
    'dbf',
    'prn',
    'qpw',
    '123',
    'wb*',
    'wq*',
    'html',
    'htm',
]
    .map(function (x) {
        return '.' + x
    })
    .join(',')


export const filterOption = (
    input: string,
    option: any,
) => {
    const inputValue = input
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd')
        .replace(/Đ/g, 'D')
        .toLowerCase(),
        optionValue =
            typeof option?.children === 'string' ? option?.children : option?.title

    return (
        optionValue
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/đ/g, 'd')
            .replace(/Đ/g, 'D')
            .toLowerCase()
            .indexOf(inputValue) >= 0
    )
}

export const formatMoney = (money: any) => {
    const config = { style: 'currency', currency: 'VND', maximumFractionDigits: 9 }
    try {
        if (money) {
            const formated = new Intl.NumberFormat('vi-VN', config).format(money);
            // money = money.toString().replace(/^0+|,|\.| /g, "")
            return formated
        } else {
            return new Intl.NumberFormat('vi-VN', config).format(0);
        }
    } catch (e) {
        return new Intl.NumberFormat('vi-VN', config).format(0);
    }
}

export const EnumContractStatus = [
    {
        id: -1,
        name: 'Tất cả hợp đồng chưa kết thúc'
    },
    {
        id: 2,
        name: 'Hợp đồng đã trả phí'
    },
    {
        id: 3,
        name: 'Hợp đồng quá hạn'
    },
    {
        id: 4,
        name: 'Hợp đồng chậm phí'
    },
    {
        id: 5,
        name: 'Hợp đồng đã đóng'
    },
]

export const EnumStatus = [
    {
        id: -1,
        name: "Tất cả"
    },
    {
        id: 1,
        name: "Hoạt động"
    },
    {
        id: 0,
        name: "Tạm khoá"
    }
]

export const EnumFields = [
    {
        id: 1,
        name: "Cho thuê xe"
    },
]

export const EnumThuChi = [
    {
        id: 20,
        name: 'Phiếu thu'
    },
    {
        id: 105,
        name: 'Phiếu chi'
    }
]

export const EnumGroupID = [
    {
        id: 21,
        name: "Bác sĩ"
    },
    {
        id: 22,
        name: "Kỹ thuật viên"
    },
    {
        id: 23,
        name: "Telesales"
    },
    {
        id: 24,
        name: "Sales"
    },
    {
        id: 26,
        name: "Marketing"
    }
]

export const renderRole = (role: number) => {
    switch (role) {
        case 21:
            return "Bác sĩ"
        case 22:
            return "Kỹ thuật viên"
        case 23:
            return "Telesales"
        case 24:
            return "Sales"
        case 26:
            return "Marketing"
        default:
            return ""
    }
}

export const EnumTypeContract = [
    {
        id: 1,
        name: "Tự do"
    },
    {
        id: 3,
        name: "3 tháng"
    },
    {
        id: 6,
        name: "6 tháng"
    }
    , {
        id: 12,
        name: "12 tháng"
    }
]

export const EnumTypeInterest = [
    {
        id: 1,
        name: "Vốn đầu tư (Ko lãi)"
    },
    {
        id: 2,
        name: "Lãi ngày"
    },
    {
        id: 3,
        name: "Lãi tháng (%) (30 ngày)"
    },
    {
        id: 4,
        name: "Lãi tháng (%) (Định kỳ)"
    },
    {
        id: 5,
        name: "Lãi tuần (%)"
    },
    {
        id: 6,
        name: "Lãi tuần (k)"
    },
]

export const EnumTypeInterestCapital = [
    {
        id: 1,
        name: "k/1 triệu"
    },
    {
        id: 2,
        name: "k/1 ngày"
    },
    {
        id: 3,
        name: "%/1 tháng"
    },
    {
        id: 4,
        name: "%/1 tuần"
    },
    {
        id: 5,
        name: "k/1 tuần"
    },
]

export const getUser = () => {
    const user = localStorage.getItem('user')
    if (user) {
        return JSON.parse(user)
    }
    return null
}

export const getShop = () => {
    const shop = localStorage.getItem('shop')
    if (shop) {
        return JSON.parse(shop)
    } else {
        return null
    }
}

export const formatDate = (date?: any): string => {
    if (date === '0001-01-01T00:00:00') return ''
    if (!date) return ''
    if (typeof date === 'number') date = new Date(date)
    return moment(date).format("DD/MM/YYYY HH:mm")
}

export const formatDate2 = (date?: any): string => {
    if (date === '0001-01-01T00:00:00') return ''
    if (!date) return ''
    if (typeof date === 'number') date = new Date(date)
    return moment(date).format("DD/MM/YYYY")
}

export const VNLocale = {
    lang: {
        locale: "vi_VN",
        today: "Hôm nay",
        now: "Bây giờ",
        backToToday: "Trở về hôm nay",
        ok: "OK",
        clear: "Xóa",
        month: "Tháng",
        year: "Năm",
        timeSelect: "Chọn thời gian",
        dateSelect: "Chọn ngày",
        weekSelect: "Chọn tuần",
        monthSelect: "Chọn tháng",
        yearSelect: "Chọn năm",
        decadeSelect: "Chọn thập kỷ",
        yearFormat: "YYYY",
        dateFormat: "D/M/YYYY",
        dayFormat: "D",
        dateTimeFormat: "D/M/YYYY HH:mm:ss",
        monthBeforeYear: true,
        previousMonth: "Tháng trước (PageUp)",
        nextMonth: "Tháng sau (PageDown)",
        previousYear: "Năm trước (Control + left)",
        nextYear: "Năm sau (Control + right)",
        previousDecade: "Thập kỷ trước",
        nextDecade: "Thập kỷ sau",
        previousCentury: "Thế kỷ trước",
        nextCentury: "Thế kỷ sau",
        shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
        shortMonths: [
            "Tháng 1",
            "Tháng 2",
            "Tháng 3",
            "Tháng 4",
            "Tháng 5",
            "Tháng 6",
            "Tháng 7",
            "Tháng 8",
            "Tháng 9",
            "Tháng 10",
            "Tháng 11",
            "Tháng 12",
        ],
    }
}

export const EnumPaymentType = [
    {
        id: 1,
        name: 'Chi nhân viên'
    },
    {
        id: 2,
        name: 'Chi đối tượng'
    }
]

export const EnumTypeInterestPawn = [
    {
        id: 0,
        name: "Trả thẳng"
    },
    {
        id: 1,
        name: "Lãi ngày (k/triệu)"
    },
    {
        id: 2,
        name: "Lãi ngày (k/ngày)"
    },
    {
        id: 3,
        name: "Lãi tháng(%)(30 ngày)"
    }
    , {
        id: 4,
        name: "Lãi tuần (%)"
    }, {
        id: 5,
        name: "Lãi tuần (VNĐ)"
    },
    {
        id: 6,
        name: "Lãi tháng (định kì)"
    },
    // {
    //     id: 10,
    //     name: "Theo tháng định kì (VNĐ)"
    // }
]

export function getNumberOfDays(start: any, end: any) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
}

export function renderStatusColorTelesales(status?: number) {
    switch (status) {
        case -11:
            return "#f50"
        case -100:
            return "#87d068"
        case -101:
            return "#F7CB73"
        case -102:
            return "#F29339"
        case -103:
            return "#077E8C"
        default:
            return "#2db7f5"
    }
}

export function renderStatusColorMedia(status?: number) {
    switch (status) {
        case -11:
            return "#f50"
        case -100:
            return "#F7CB73"
        default:
            return "#2db7f5"
    }
}

export function renderStatusColorContract(status?: number) {
    switch (status) {
        case -99:
            return "#f50"
        case -100:
            return "#F7CB73"
        case -101:
            return "#87d068"
        default:
            return "#2db7f5"
    }
}

export function differenceContractTime(toDate: any) {
    const startDate = new Date()
    const endDate = new Date(toDate)

    if (startDate > endDate) {
        return 'Đã qua ngày hẹn'
    }

    //@ts-ignore
    const timeDifference = endDate - startDate;
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `Hẹn giờ: ${days} ngày, ${hours} giờ, ${minutes} phút`
}