export const firebaseConfig = {
  apiKey: "AIzaSyCwnnD3peQyVwUVAIXz74jvSoBfNWgZufY",
  authDomain: "crm-spa-eaff1.firebaseapp.com",
  projectId: "crm-spa-eaff1",
  storageBucket: "crm-spa-eaff1.appspot.com",
  messagingSenderId: "912449855377",
  appId: "1:912449855377:web:f9411d97acfc9211538430",
  measurementId: "G-H9LXYNYGZE",
};

export const BASE_URL = "http://api.glad.ai.vn/";
