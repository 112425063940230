import moment from "moment";
import { all, call, delay, put, takeEvery } from "redux-saga/effects";
import {
  ContractService,
  DataCustomerService,
  MetaService,
  ShopService,
  UserV2Service,
  WalletService,
  WalletTransferService,
} from "../../services";
import actions from "./actions";

function* getShop(): any {
  try {
    const response = yield call(ShopService.getShopActive);
    if (response.Result === 1) {
      yield put(actions.getShopSuccess(response.Data));
    } else {
      yield put(actions.getShopError());
    }
  } catch (error) {
    yield put(actions.getShopError());
  }
}

function* getCountTransfer(): any {
  try {
    const response = yield call(WalletTransferService.getListPending);
    if (response.Result === 1) {
      yield put(actions.getCountTransferSuccess(response.Data.length));
    } else {
      yield put(actions.getCountTransferError());
    }
  } catch (error) {
    yield put(actions.getCountTransferError());
  }
}

function* getCountContract(): any {
  try {
    const response = yield call(ContractService.getContractTreatment);
    if (response.Result === 1) {
      yield put(actions.getCountContractSuccess(response.Total));
    } else {
      yield put(actions.getCountContractError());
    }
  } catch (error) {
    yield put(actions.getCountContractError());
  }
}

function* getMoney(): any {
  try {
    const response = yield call(WalletService.getMoneyUser);
    if (response.Result === 1) {
      yield put(actions.getMoneySuccess(response.Data));
    } else {
      yield put(actions.getMoneyError());
    }
  } catch (error) {
    yield put(actions.getMoneyError());
  }
}

function* getDaySchedule(): any {
  try {
    const response = yield call(DataCustomerService.getDataCustomerToday);
    if (response.Result === 1) {
      yield put(actions.getDayScheduleSuccess(response.Data.length));
    } else {
      yield put(actions.getDayScheduleError());
    }
  } catch (error) {
    yield put(actions.getDayScheduleError());
  }
}

function* getCustomerPaymentAppointment(): any {
  try {
    const response = yield call(ContractService.getCustomerPaymentAppointment);
    if (response.Result === 1) {
      yield put(actions.getAppointmentSuccess(response.Data.length));
    } else {
      yield put(actions.getAppointmentError());
    }
  } catch (error) {
    yield put(actions.getAppointmentError());
  }
}

function* getPaymentSchedule(): any {
  try {
    const response = yield call(ContractService.getContractNotify);
    if (response.Result === 1) {
      yield put(actions.getPaymentScheduleSuccess(response.Data.length));
    } else {
      yield put(actions.getPaymentScheduleError());
    }
  } catch (error) {
    yield put(actions.getPaymentScheduleError());
  }
}

function* getAds(): any {
  try {
    const response = yield call(MetaService.getAllUserByPosition, {
      positionId: 5,
    });
    if (response.Result === 1) {
      yield put(actions.getAdsSuccess(response.Data));
    } else {
      yield put(actions.getAdsError());
    }
  } catch (error) {
    yield put(actions.getAdsError());
  }
}

function* getTelesales(): any {
  try {
    const response = yield call(MetaService.getAllUserByPosition, {
      positionId: 7,
    });
    if (response.Result === 1) {
      yield put(actions.getTelesalesSuccess(response.Data));
    } else {
      yield put(actions.getTelesalesError());
    }
  } catch (error) {
    yield put(actions.getTelesalesError());
  }
}

function* getStaffShop(): any {
  try {
    const response = yield call(UserV2Service.getListStaffByShop);
    if (response.Result === 1) {
      yield put(actions.getStaffShopSuccess(response.Data));
    } else {
      yield put(actions.getStaffShopError());
    }
  } catch (error) {
    yield put(actions.getStaffShopError());
  }
}

function* getSales(): any {
  try {
    const response = yield call(MetaService.getAllUserByPosition, {
      positionId: 3,
    });
    if (response.Result === 1) {
      yield put(actions.getSalesSuccess(response.Data));
    } else {
      yield put(actions.getSalesError());
    }
  } catch (error) {
    yield put(actions.getSalesError());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_SHOP, getShop)]);
  yield all([takeEvery(actions.GET_COUNT_TRANSFER, getCountTransfer)]);
  yield all([takeEvery(actions.GET_COUNT_CONTRACT, getCountContract)]);
  yield all([takeEvery(actions.GET_MONEY, getMoney)]);
  yield all([takeEvery(actions.GET_DAY_SCHEDULE, getDaySchedule)]);
  yield all([takeEvery(actions.GET_PAYMENT_SCHEDULE, getPaymentSchedule)]);
  yield all([takeEvery(actions.GET_ADS, getAds)]);
  yield all([takeEvery(actions.GET_TELESALES, getTelesales)]);
  yield all([takeEvery(actions.GET_STAFF_SHOP, getStaffShop)]);
  yield all([takeEvery(actions.GET_SALES, getSales)]);
  yield all([
    takeEvery(actions.GET_CUSTOMER_APPOINTMENT, getCustomerPaymentAppointment),
  ]);
}
