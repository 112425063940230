import React, { lazy, Suspense } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import AuthGuard from "../guards/AuthGuard";
import { pathname } from "./path";
import CMSLayout from "../layouts";
import AdminGuard from "../guards/AdminGuard";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: pathname.login,
      element: <Login />,
    },
    {
      path: pathname.root,
      element: (
        <AuthGuard>
          <CMSLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Home /> },
        { path: "/admin", element: <Admin /> },
        { path: "/marketing/ads", element: <Ads /> },
        { path: "/marketing/media", element: <Media /> },
        { path: "/marketing/telesales", element: <Telesales /> },
        {
          path: pathname.profile,
          element: <Profile />,
        },
        { path: "/revenue-expenditure", element: <PaymentAndCollect /> },
        { path: "/capital", element: <Capital /> },
        { path: "/superadmin", element: <Owner /> },
        { path: "/contract/pending", element: <ContractPending /> },
        { path: "/contract", element: <Contract /> },
        { path: "/contract/treatment", element: <ContractTreatment /> },
        { path: "/config_wallet_user", element: <ConfigWalletUser /> },
        { path: "/contract/config_status", element: <ConfigStatusContract /> },
        { path: "/transfer_wallet", element: <SwitchWallet /> },
        { path: "/wallet/transfer", element: <RequestTransfer /> },
        { path: "/wallet", element: <WalletConfig /> },
        { path: "/customer/dayschedule", element: <CustomerDaySchedule /> },
        { path: "/customer/daypayment", element: <CustomerDayPayment /> },
        { path: "/customer-appointment", element: <CustomerAppointment /> },
        { path: "/report/service_fee_detail", element: <ServiceFeeDetail /> },
        { path: "/product", element: <Products /> },
        { path: "/warehouse/report", element: <ReportWarehouse /> },
        { path: "/marketing/report", element: <ReportMarketing /> },
        { path: "/sales/report", element: <ReportSales /> },
        { path: "/home", element: <HomeSales /> },
        { path: "/recall", element: <Recall /> },
        {
          path: "/payment-and-collect/report",
          element: <ReportPaymentAndCollect />,
        },
        {
          path: "/report_treatment",
          element: <ReportTreatment />,
        },
      ],
    },
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

const ReportTreatment = Loadable(
  lazy(() => import("../pages/reports/ReportTreatment"))
);
const NotFound = Loadable(lazy(() => import("../components/NotFound")));
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Home = Loadable(lazy(() => import("../pages/home/Home")));
const Profile = Loadable(lazy(() => import("../pages/auth/Profile")));
const Owner = Loadable(lazy(() => import("../pages/Owner")));

//Marketing
const Ads = Loadable(lazy(() => import("../pages/marketing/Ads/Ads")));
const Media = Loadable(lazy(() => import("../pages/marketing/Media/index")));
const Recall = Loadable(
  lazy(() => import("../pages/marketing/Telesales/Recall"))
);
const ConfigWalletUser = Loadable(
  lazy(() => import("../pages/marketing/ConfigWalletUser/index"))
);
const Telesales = Loadable(
  lazy(() => import("../pages/marketing/Telesales/index"))
);

//Admin
const Admin = Loadable(lazy(() => import("../pages/admin/index")));

// Tài chính - Kế toán
const PaymentAndCollect = Loadable(
  lazy(() => import("../pages/accouting/PaymentAndCollect"))
);
const Capital = Loadable(lazy(() => import("../pages/capital/Capital")));
const SwitchWallet = Loadable(
  lazy(() => import("../pages/accouting/SwitchWallet"))
);
const WalletConfig = Loadable(lazy(() => import("../pages/accouting/Wallet")));
const RequestTransfer = Loadable(
  lazy(() => import("../pages/accouting/RequestTransfer"))
);

// Hồ sơ khách hàng
const ContractPending = Loadable(
  lazy(() => import("../pages/contract/ContractPending"))
);
const Contract = Loadable(lazy(() => import("../pages/contract/index")));
const ConfigStatusContract = Loadable(
  lazy(() => import("../pages/contract/ConfigStatus"))
);
const ContractTreatment = Loadable(
  lazy(() => import("../pages/contract/ContractTreatment"))
);
const CustomerDaySchedule = Loadable(
  lazy(() => import("../pages/customer/CustomerDaySchedule"))
);
const CustomerDayPayment = Loadable(
  lazy(() => import("../pages/customer/CustomerDayPayment"))
);
const CustomerAppointment = Loadable(
  lazy(() => import("../pages/customer/CustomerAppointment"))
);

// báo cáo
const ServiceFeeDetail = Loadable(
  lazy(() => import("../pages/reports/ServiceFeeDetail"))
);
const ReportMarketing = Loadable(
  lazy(() => import("../pages/reports/ReportMarketing"))
);
const ReportSales = Loadable(
  lazy(() => import("../pages/reports/ReportContract"))
);
const ReportPaymentAndCollect = Loadable(
  lazy(() => import("../pages/reports/ReportPaymentAndCollect"))
);
const HomeSales = Loadable(lazy(() => import("../pages/home/HomeSales")));
const HomeTelesale = Loadable(lazy(() => import("../pages/home/HomeTelesale")));

// Kho
const Products = Loadable(lazy(() => import("../pages/warehouse/index")));

const ReportWarehouse = Loadable(
  lazy(() => import("../pages/warehouse/Report"))
);
